<template>
  <div>
    <v-layout class="table-result">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
        <tr v-for="r in 6" :key="r">
          <td v-for="c in channel.column" :key="c" style="padding:0px; margin:0px">
            <div v-if="c > channel.column-channel.fix_column" class="td-table-style">
                  <button 
                      :class="matchResult(r, c).color"
                      :disabled="matchResult(r, c).disable || channel.is_open != false"
                      @click="_select(r, calculateColumn(c))"><div class="justify-center text-center fight-no-style"> {{  matchResult(r, c).fight_no}}</div></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </v-layout>
    <v-layout class="py-5" justify-center>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult.BANKER }}
        </span>
      </v-avatar>

      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult.PLAYER }}
        </span>
      </v-avatar>

      <v-avatar class="mx-5" color="green" size="56">
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult.TIE }}
        </span>
      </v-avatar>

      <v-avatar class="mx-5" color="grey" size="56">
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult.CANCEL }}
        </span>
      </v-avatar>
    </v-layout>

    <!--  dialog result  -->
    <v-dialog v-model="dialogResult" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8" v-if="objCoTypeOfBetting">
          <v-btn-toggle v-model="defineResult.result_code">
            <btn-result v-if="disableRed !=true" :value="1" :color="'cir-blue'" :disabled="disableRed"  />
            <btn-result v-if="disableBlue !=true" :value="0" :color="'cir-red'" :disabled="disableBlue"  />
            <btn-result :value="2" :color="'cir-green'" />
            <btn-result :value="3" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirm=true" :disabled="defineResult.result_code == null && defineResult.result_code!=0">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogConfirm"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="defineResult.result_code ==1"  class="wala-text bold">Player</span> 
              <span v-if="defineResult.result_code ==0" class="meron-text bold">Banker</span> 
              <span v-if="defineResult.result_code ==2"  class="tie-text bold">Tie</span> 
              <span v-if="defineResult.result_code ==3" class="cancel-text bold">Cancel</span> 
            <br>
            </div>
           <div :class=" defineResult.result_code ==1 ? ' confirm-circle wala' :defineResult.result_code==0?' confirm-circle meron':defineResult.result_code==2?' confirm-circle tie':defineResult.result_code==3?' confirm-circle cancel':''">
           </div>
          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirm = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResult"
              :disabled="loadingResult"
            >
              Submit
            </v-btn>
              <td style="text-align:center " v-if="loadingResult">
                <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </td> 
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters } from "vuex";
export default {
  components: {BtnResult},
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      dialogResult: false,
      dialogConfirm:false,
      defineResult: {
        chanel_id: null,
        resultTypeId: null
      },
      disableBlue : false,
      disableRed : false,
      break : false,
      allowToClick : true,
      loadingData : false
    }
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    },
    allowToClick: function(){},
    loadingResult: function(newV){
      if(newV == false) this.cancelResult();
    }
  },
  mounted(){
    
  },
  computed: {

...mapGetters("$_baccaratCh18Channel", {
  loadingResult: "getLoadingResult",
}),
},
  methods: {
    _allowSubmit(){
     
      // console.log(this.allowToClick)
      // this.allowToClick = false
      // console.log( this.allowToClick)
    },
    _select(r, c) {
      this.disableRed = false;
      this.disableBlue = false;
      this.defineResult.rid = r
      this.defineResult.cid = c
      this.dialogResult = true
      for (let i = 0; i < this.objCoResult.length; i = i + 1) {
        if(this.objCoResult[i].name != "CANCEL" && this.objCoResult[i].name != "TIE" ){
          if(this.defineResult.rid > 1 && this.objCoResult[i].name == "BANKER" ) this.disableRed = true;
          if(this.defineResult.rid > 1 && this.objCoResult[i].name == "PLAYER" ) this.disableBlue = true;
          if(this.defineResult.rid == 1 && this.objCoResult[i].name == "PLAYER" ) this.disableRed = true;
          if(this.defineResult.rid == 1 && this.objCoResult[i].name == "BANKER" ) this.disableBlue = true;
          break;
        }
      }
    },
    //Match result column and row
    matchResult(r, c) {
      let flag, code, f_no;
      this.objCoResult.forEach(item => {
        if (item.rid === r && item.cid === c) {
          flag = true
          code =item.color
          f_no= item.fight_no
          return false;
        }
      })
      if (flag) {
        return {
          color: code,
          fight_no: f_no,
          disable: true
        }
      } else {
        return {
          color: "no-circle",
          disable: false
        }
      }
    },
    saveResult() {
          this.defineResult.chanel_id = this.channel._id
          // this.defineResult.resultTypeId = this.objCoTypeOfBetting[this.defineResult.result_code]._id
          if(this.defineResult.result_code == 0){
            this.defineResult.resultTypeId = '615d4a899e9ab44fe23eb9db';
          }else if(this.defineResult.result_code == 1){
            this.defineResult.resultTypeId = '615d4aa59e9ab44fe23eb9dd';
          }else if(this.defineResult.result_code == 2){
            this.defineResult.resultTypeId = '615d5a609e9ab44fe23eba42';
          }else{
            this.defineResult.resultTypeId = '635a3646a9b6a9cbc6206d7c';
          }
          this.$emit("submitResult", this.defineResult);
          // this.defineResult.result_code = null;
          if(this.loadingResult == false) {
            this.dialogResult = false
            this.dialogConfirm = false
            this.defineResult.rid = null
            this.defineResult.cid = null
          }
    },
    cancelResult() {
      this.defineResult.rid = null
      this.defineResult.cid = null
      this.defineResult.result_code = null
      this.dialogResult = false
      this.dialogConfirm = false
    },
    calculateColumn(c) {
      // if (this.channel.column - this.channel.fix_column > 0) {
      //   return c + (this.channel.column - this.channel.fix_column)
      // } else {
      //   return c;
      // }
      return c
    }
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
td:hover{
  background-color: #ffbf00 !important;;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
