export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    create_bankUser: 'Create Bank User',
    update_bankUser: 'Update Bank User',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'Welcome:',
    account_balance: 'Account Balance',
    user_role: 'User Role',
    create_configUser : "Create Config User",
    update_configUser : "Update Config User",
    config_user :'Config User',
    limitBetting: "Limit Betting For Agent",
    payOut: "Pay Out",
    roleLevel: "Role Level",
    update_payOut: "Updating PayOut",
    update_roleLevel: "Updating Role Level",
    user_limit_betting: "Limit Betting (កំណត់ការភ្នាល់)",
    user_limit_betting_usd: "Limit Betting USD",
    user_limit_betting_kh: "Limit Betting KH",
    user_limit_betting_vn: "Limit Betting VN",
    user_config_betting: "Limit Betting",
    total:"Total",
    enablePlayers:"Players Controller",
    widthdrawStatement:"Cash-out Statement",
    depositStatement:"Cash-in Statement",
    select_operation:"Select Operation",
    list_user_ip:"List Login ip",
    setConfig:"Update Config User",
    update_vip_percentage : "Update VipPercentage",
    app_list: "App List",
    create_app: "Create App",
    update_app: "Update App",
    login:'LOGIN',
    log:'Log in',
    logging: 'Logging in',
    changeLanguage :'Change Language',
    password: 'Password',
    create_dealer: "Create Dealer",
    update_dealer: "Update Dealer",
    dealer_title: "Dealer List",
    win_lose:"Win/Lose",
    maintenance_title: "Maintenance List",
    lottoSetting: "Lotto Setting",
    yukiSetting: "Yuki Setting",
    create_lottoSetting: "Create Lotto Setting",
    update_lottoSetting: "Update Lotto Setting",
    create_yukiSetting : "Create Yuki Setting",
    update_yukiSetting: "Update Yuki Setting",
    configSlotLimit:"Config Limit For Slotmachine"
};
