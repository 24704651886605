import han3Ch21Channel from '@/modules/channelGames/han3Ch21Channel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/han3Ch21Channel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: han3Ch21Channel,
        name: 'han3Ch21Channel'
    }]
}];

export default routes;