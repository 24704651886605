/* eslint-disable no-unused-vars */
import axios from 'axios';
import Request from '@/services/axios'
let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    commit('UPDATE_LOADING', true)
    let data = preData.data
    // let reportUrl = process.env.VUE_APP_API_REPORT_URL + 'adminReport/getViewTopRangeWinnerApiBranch'
    const response = await request.post({
      url: 'adminReport/getViewHistoryByFightTigerDragon',
      data,
    });
    if (response.data.code) {
      commit('LIST_STATEMENT', response.data.data);
      // commit('COUNT_STATEMENT', response.data.data.objectCount);
      commit('UPDATE_LOADING', false)
    }
};

const fetchLimitData = async({ commit }, preData) => {
  commit('UPDATE_LOADING', true)
  let data = preData;
  console.log("data",data);
  const response = await request.post({
    url: "limitBetting/getParentLimitBetting",
    data,
  });
  if (response.data.code) {
    console.log(response.data.data.object);
    commit('PARENT_LIMIT_DATA', response.data.data.object);
    commit('UPDATE_LOADING', false)
  }
};

const updateConfigUser = async ({ commit }, { vm, d }) => {
  let data = d
  const response = await request.post({
      url: 'configUser/update/' + d._id,
      data
  });
  if (response.data.code) {
      vm.$toastr.s(`${response.data.message.descriptions}`)
      commit('UPDATE_POPUP', false)
  } else vm.$toastr.e(`${response.data.message.descriptions}`)
};


const fetchChannelType = async({ commit }) =>  {
  let data = { 
    "base_url": process.env.VUE_APP_API_REPORT_URL
  }
  const response = await request.post({
    url: 'users/getChannelTypeReportBySub',
    data
  });
  if (response.data.code) {
      commit('LIST_CHANNEL_TYPE', response.data.data.object);
   }
   //prevent db don't have data yet
   if(response.data.data.object == null) {
    let object = {
      "_id": "62b3231fcabe536cbc9cb2b4",
      "channel_type": 1,
      "base_url": "http://localhost:3080/v1/"
  }
    commit('LIST_CHANNEL_TYPE', object);
  }
}

export default {
    fetchListStatement,
    fetchChannelType,
    fetchLimitData,
    updateConfigUser
};