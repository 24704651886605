import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	bankUser: {
		object:[],
		objectCount: null
	},
	currentBankUser: {
		user_id: null,
		bank_id: null,
		account_name: null,
		account_number: null,
		account_info: null,
		is_active: true,
		is_enable: true,
		currency_type: null,
		order: null,
	},
	bankUserParent: [],
	popUp: false,
	deleteItems: [],
	loading: true
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
