<template>
  <div>
    <v-card :dark="darkmode" :style="loadingResult ? 'opacity:0' : ''">
      <form-channel :imageUrl="imageUrl" :getAllowScanCard="getAllowScanCard" :getDealerDialog="getDealerDialog"
        :dealerInfo="getDealerInfo" :channel="currentChannel.currentChannel" :objSnNumber="objSnNumber"
        :socketChannelType="dataFromSocket.type" :socketChannelCountdown="dataFromSocket.countDown"
        :selectResult="selectResult" v-on:clearImageUrl="getScanCard" v-on:submitClearResult="handleSubmitClearResult"
        v-on:submitCardCode="handleSubmitCardCode" v-on:submitResult="handleSubmitResult"
        v-on:submitApongResult="handlesubmitApongResult" v-on:submitUpdateChanel="handleSubmitUpdateChanel"
        v-on:popUpDealer="handlePopupDealer" v-on:submitBarcodeDealer="handleScanBarcodeDealer"
        v-on:submitResetResult="handleResetResult"  v-on:startGame="handleSubmitStartGame"/>
      <table-result :summaryResult="objSummarySnResult" :objSnResult="objSnResult"
        :channel="currentChannel.currentChannel" :rows="6" :cols="20" />
      <!-- <div style="width:100%" class="text-right result-btn-container">
       <v-btn @click="okToResetResult=true" color="warning m-5" class="text-right" :disabled="currentChannel.currentChannel.is_open!=null  ? true: false ">
      Reset Result
      </v-btn>
    </div> -->
      <!-- <v-dialog
      v-model="okToResetResult"
      persistent
      max-width="390"
      max-height="320"
    >
      <v-card style="height:250px">
        <v-card-title class="justify-center res-img-containter" style="height:60%">
          Do you want to reset result
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey ml-4 confirm-btn" @click="okToResetResult=false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1 mr-4 confirm-btn"  @click="_resetResult1" :disabled="currentChannel.currentChannel.is_open!=null  ? true: false " >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    </v-card>
    <div v-if="loadingResult">
      <div class="ring">សូមរង់ចាំ
        <span class="span-loading"></span>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/channel.css";
import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";
// import Dialog from '../MenuManagement/_components/Dialog.vue';

export default {
  components: {
    formChannel,
    tableResult,
    // Dialog
  },
  data: () => {
    return {
      dataFromSocket: [],
      imageUrl: "",
      selectResult: {
        order: null,
        _id: null,
      },
      loading: false,
      okToResetResult: false,
      dialogResult: false,
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode"
    }),
    ...mapGetters("$_apongChannel", {
      currentChannel: "getCurrentChannel",
      objSnNumber: "getBtnResults",
      objSummarySnResult: "getSummaryResult",
      objSnResult: "getResultTable",
      getDealerDialog: "getDealerDialog",
      getDealerInfo: "getDealerInfo",
      getResultEvent: "getResultEvent",
      getDialogResult: "getdialogResult",
      getCurrentCard: "getCurrentCard",
      getAllowScanCard: "getAllowScanCard",
      loadingResult: "getLoadingResult",
    })
  },

  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      await this.fetchBtnResult();
      await this.getScanCard();
    }
  },
  async mounted() {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }

    this.$socket.on("client-update-channel-info", data => {
      if (data) {
        this.currentChannel.currentChannel = data
      }
    });
    this.$socket.on("client-is-ready", data => {
      if (data) {
        this.fetchCurrentChannel();
        // this.currentChannel.currentChannel = data
      }
    });
    this.$socket.on("client-start-count-down", data => {
      if (data) {
        this.dataFromSocket = data;
        if (this.dataFromSocket.countDown <= 5) {
          if (this.dataFromSocket.countDown <= 5 && this.dataFromSocket.countDown > 0) {
            this.playAudioTing();
            this.fetchCurrentChannel();
          } else if (this.dataFromSocket.countDown == 0) {
            this.playAudioGoodLuck();
          }
        }
      }
    });

    this.$el.addEventListener('click', this.onClick);
  },
  methods: {
    async getScanCard() {
      await this.fetchCurrentCard();
      let getData = this.getCurrentCard;

      if (getData) {
        this.imageUrl = getData.imageUrl;
      } else {
        this.imageUrl = '';
      }
    },

    async handleResetResult() {
      await this.resetResult({ vm: this });
    },

    async handleSubmitResult(data) {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        sn_number_id: data._id
      };
      await this.createResult({ vm: this, data: submitData });
    },
    async handlesubmitApongResult() {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
      };
      await this.scanResult({ vm: this, data: submitData });
    },
    async handleSubmitUpdateChanel(dataUpdateChanel) {
      await this.updateChannel({ vm: this, data: dataUpdateChanel })
    },

    async handleScanBarcodeDealer(barcode) {
      let dealerId = this.$cookies.get("dealerId");

      let submitData;
      if (dealerId) {
        submitData = {
          'barcode': barcode,
          'old_barcode': dealerId.barcode,
          'channel_id': this.currentChannel.currentChannel._id,
        }
      } else {
        submitData = {
          'barcode': barcode,
          'old_barcode': '',
          'channel_id': this.currentChannel.currentChannel._id,
        }
      }
      await this._fetchGetDealerInfo({ vm: this, data: submitData });

    },

    async handleSubmitClearResult() {
      await this.UpdateClearResult({ vm: this, data: {} });
      this.imageUrl = "";
      await this.fetchCurrentChannel();
    },
    async handleSubmitStartGame() {
      this.imageUrl = "";
      await this.startGame({ vm: this, data: {} });
    },
    async handleSubmitCardCode(cardCode) {
      // if (!this.getAllowScanCard) {
      let submitData = {
        'dealer_id': this.getDealerInfo._id,
        'channel_id': this.currentChannel.currentChannel._id,
        'shoe': this.currentChannel.currentChannel.shoe,
        'barcode': cardCode,
        'fight_no': this.currentChannel.currentChannel.fight_no,
        'group_no': this.currentChannel.currentChannel.group_no
      }
      await this.submitCardCode({ vm: this, data: submitData });
      await this.getScanCard();
      await this.fetchCurrentChannel();
      // }else{
      //   this.vm.$toastr.e("Previous scan card is not complete yet")
      // }
    },

    playAudioTing() {
      let audio = new Audio("/sound/ting1s.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () { });
      }
    },
    playAudioGoodLuck() {
      let audio = new Audio("/sound/good luck.mp3");
      let promise = audio.play();
      if (promise) {
        //Older browsers may not return a promise, according to the MDN website
        promise.catch(function () { });
      }
    },
    // _resetResult1(){
    //   this.resetResult( {vm: this});
    //   this.okToResetResult = false
    // },
    handlePopupDealer(payload) {
      this._popUpDialogDealer(payload);
    },

    ...mapMutations("$_apongChannel", ["UPDATE_CHANNEL", "SET_RESULT", "SUMMARY_RESULT", "POPUP_DIALOG_RESULT", "POPUP_DIALOG_SCAN_DEALER", "UPDATE_DEALER_INFO",
      "RESULT_EVENT",
      "CURRENT_CARD"]),
    ...mapActions("$_apongChannel", [
      "fetchCurrentChannel",
      "fetchBtnResult",
      "createResult",
      "scanResult",
      "updateChannel",
      "UpdateClearResult",
      "resetResult",
      "_fetchGetDealerInfo",
      "_popUpDialogDealer",
      "submitCardCode",
      "fetchCurrentCard",
      "startGame"
    ])
  }
};
</script>

<style lang="scss" scoped>
.confirm-btn {
  color: white !important;
  width: 120px !important;
}

.result-btn-container {
  padding-right: 25px;
  margin-bottom: 25px
}
</style>
