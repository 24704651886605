import ocStatementSummaryAgentRp from '@/modules/statementReports/currency/ocStatementSummaryAgentRp';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryAgentRp',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryAgentRp,
        name: 'ocStatementSummaryAgentRp'
    }]
}];

export default routes;