<template>
  <div>
  <v-card :style="loadingResult ? 'opacity:0' : ''">
    <form-channel :channel="currentChannel.currentChannel" :objCoTypeOfBetting="objCoTypeOfBetting"
      :socketChannelType="dataFromSocket.type" :socketChannelCountdown="dataFromSocket.countDown"
      :selectResult="selectResult" :dealerInfo="getDealerInfo" v-on:submitBarcodeDealer="handleScanBarcodeDealer"
      :imageUrl="imageUrl"
      :getDealerDialog="getDealerDialog"
      :getResultEvent="getResultEvent"
      :getAllowScanCard="getAllowScanCard"
      v-on:submitFinishedCard="handleFinishedCard"
      v-on:submitClearCard="handleClearCard"
      v-on:submitVoidShoe="handleVoidShoe"
      v-on:submitUpdateStatus="handleUpdateChanelStatus" v-on:submitUpdateChanel="handleSubmitUpdateChanel"
      v-on:submitResetResult="handleResetResult" v-on:submitResetShoe="handleResetShoe"
      v-on:submitDynamicRes="handleDynamicRes"  v-on:submitCardCode="handleSubmitCardCode"
      v-on:popUpDealer="handlePopupDealer"
      />

    <table-result v-on:submitResult="handleSubmitResult" :channel="currentChannel.currentChannel"
      :objCoTypeOfBetting="objCoTypeOfBetting" :objCoResult="objCoResult" :loadingResult="loadingResult"
      :objSummaryCoResult="objSummaryCoResult" v-on:submitDynamicRes="handleDynamicRes" />
  </v-card>
  <div v-if="loadingResult" >
            <div class="ring">សូមរង់ចាំ
                <span class="span-loading"></span>
            </div></div>
</div>
</template>

<script>
import "@/assets/css/channel.css";

import formChannel from "./_components/formChannel";
import tableResult from "./_components/tableResult";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    formChannel,
    tableResult,
  },
  data: () => {
    return {
      dataFromSocket: [],
      imageUrl: "",
      selectResult: {
        order: null,
        _id: null,
      },
      loading: false,
      dialogResult: false,

    };
  },
  computed: {
    
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_yukiChannel", {
      currentChannel: "getCurrentChannel",
      objCoTypeOfBetting: "getBtnResults",
      objSummaryCoResult: "getSummaryResult",
      objCoResult: "getResultTable",
      objKdPayout: "getKdPayout",
      loadingResult: "getLoadingResult",
      getLastResult: "getLastResult",
      getSkipColumn: "getSkipColumn",
      getSkipRow: "getSkipRow",
      getSkipBetween: "getSkipBetween",
      getDealerInfo: "getDealerInfo",
      getDealerDialog : "getDealerDialog",
      getDialogResult : "getdialogResult",
      getResultEvent : "getResultEvent",
      getCurrentCard: "getCurrentCard",
      getAllowScanCard: "getAllowScanCard"
    }),
  },

  async created() {
    await this.initialData().then(()=> {
      let getData  = this.getCurrentCard;
      if(getData){
        this.imageUrl = getData.imageUrl;
      }
        
    });
  },

  watch: {
     getResultEvent : function (newV){
      if( newV == true ) {
        this.imageUrl = "";
        
      }
   },
    objCoResult: function () { },
    loadingResult: function () { },
  },
  async mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    
    await this.fetchCurrentCard();
      this.$socket.on("client-start-count-down", data => {
        if (data) {
          this.dataFromSocket = data;
          if(this.dataFromSocket.countDown == 5){
              this.fetchCurrentChannel();
            }
        }
      });
      this.$el.addEventListener('click', this.onClick);
  },
  destroyed() {
    this.$el.removeEventListener('click', this.onClick);
  },
  methods: {
    async initialData(){
      await this.fetchUserAccessPermission(this.$route.path);
      if (this.checkUserPermission("VIEW")) {
      await this.fetchCurrentChannel();
      await this.fetchBtnResult();
    }
    },  
    async getScanCard(){
      await this.fetchCurrentCard();
      let getData  = this.getCurrentCard;
      if(getData){
        this.imageUrl = getData.imageUrl;
      }
    },
    doSingleValue(str){
      let objStr = str.toString();
      return parseInt(objStr.substring(objStr.length - 1));
    },
    async handleResetResult() {
      await this.resetResult();
    },
    async handleResetShoe() {
      await this.resetShoe();
    },
    async handleScanBarcodeDealer(barcode) {
      let dealerId = this.$cookies.get("dealerId");
     
      let submitData;
      if(dealerId){
        submitData = {
          'barcode': barcode,
          'old_barcode': dealerId.barcode,
          'channel_id': this.currentChannel.currentChannel._id,
        }
      }else{
        submitData = {
          'barcode': barcode,
          'old_barcode': '',
          'channel_id': this.currentChannel.currentChannel._id,
        }
      }
      await this._fetchGetDealerInfo({ vm: this, data: submitData });

    },
    async handleSubmitCardCode(cardCode) {
      if (!this.getAllowScanCard) {
      let submitData = {
        'dealer_id': this.getDealerInfo._id,
        'channel_id': this.currentChannel.currentChannel._id,
        'shoe': this.currentChannel.currentChannel.shoe,
        'barcode': cardCode,
        'fight_no': this.currentChannel.currentChannel.fight_no,
        'group_no': this.currentChannel.currentChannel.group_no
      }
     await this.submitCardCode ({ vm: this, data: submitData });
      await this.getScanCard();
    }else{
      this.vm.$toastr.e("Previous scan card is not complete yet")
    }
    },
    async handleUpdateChanelStatus() {
      let submitData;
      submitData = {
          _id: this.currentChannel.currentChannel._id,
          'dealer_id': this.getDealerInfo._id,
        };
      await this.UpdateChanelStatus({ vm: this, data: submitData });
      this.imageUrl = '';
    },
    async handleFinishedCard() {
      let submitData;
      if (this.objKdPayout) {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          meron_percentage: parseInt(this.objKdPayout.meron_percentage),
          wala_percentage: parseInt(this.objKdPayout.wala_percentage),
        };
      } else {
        submitData = {
          _id: this.currentChannel.currentChannel._id,
          'dealer_id': this.getDealerInfo._id,
        };
      }
      await this.UpdateFinishedCard({ vm: this, data: submitData });
      await this.getScanCard();
    },
    async handleClearCard() {
      await this.UpdateClearCard({ vm: this, data: {} });
      this.imageUrl = "";
      await this.fetchCurrentChannel();
    },
    async handleSubmitResult(data) {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        co_type_of_betting_id: data.resultTypeId,
        pair_banker: data.pairBanker,
        pair_player: data.pairPlayer,
      };
      let getLastData = {
        group_no: this.currentChannel.currentChannel.group_no,
        shoe: this.currentChannel.currentChannel.shoe,
        channel_type: parseInt(this.$cookies.get('channel-type')),
      };
      await this._fetchLastResult(getLastData);
      
      let submitNewData = await this._defineColumnRow(
        this.getLastResult,
        submitData
      );
      await this.createResult({ vm: this, data: submitNewData });
    },

    async _defineColumnRow(oldData, newData) {
      // check if old and new has the same submit type
      if (
        oldData.type_id == newData.co_type_of_betting_id ||
        newData.co_type_of_betting_id == "615d5a609e9ab44fe23eba42" ||
        newData.co_type_of_betting_id == "635a3646a9b6a9cbc6206d7c"
      ) {
        // check if old cid colum is smaller than 5
        if (oldData.rid < 5) {
          if (oldData.isNewTable == false) {
            let data = {
              cid: oldData.cid,
              rid: oldData.rid,
              group_no: this.currentChannel.currentChannel.group_no,
              shoe: this.currentChannel.currentChannel.shoe,
              channel_type: parseInt(this.$cookies.get('channel-type')),
            };
            await this._fetchCheckRowExist(data);
            if (this.getSkipRow) {
              newData.cid = oldData.cid + 1;
              newData.rid = oldData.rid;
            } else {
              await this._fetchCheckBetweenExist(data);
              if (this.getSkipBetween) {
                newData.cid = oldData.cid + 1;
                newData.rid = oldData.rid;
              } else {
                newData.cid = oldData.cid;
                newData.rid = oldData.rid + 1;
              }
            }
          } else if (oldData.isNewTable == true) {
            newData.cid = 1;
            newData.rid = 1;
          }
        } else {
          newData.cid = oldData.cid + 1;
          newData.rid = oldData.rid;
        }
      } else {
        let data = {
          cid: oldData.cid + 1,
          rid: 1,
          group_no: this.currentChannel.currentChannel.group_no,
          shoe: this.currentChannel.currentChannel.shoe,
          channel_type: parseInt(this.$cookies.get('channel-type')),
        };
        await this._fetchCheckColumExist(data);
        if (this.getSkipColumn && this.getSkipColumn.cid) {
          newData.cid = this.getSkipColumn.cid + 1;
          newData.rid = 1;
        } else {
          newData.cid = 1;
          newData.rid = 1;
        }
      }
      return newData;
    },

    async handleSubmitUpdateChanel(dataUpdateChanel) {
      delete dataUpdateChanel.enable;
      await this.updateChannel({ vm: this, data: dataUpdateChanel });
    },
    async handleVoidShoe() {
      let submitData = {
        channel_id: this.currentChannel.currentChannel._id,
        co_type_of_betting_id: "635a3646a9b6a9cbc6206d7c"
      };

      await this.voidShoe({ vm: this, data: submitData });
      this.imageUrl = "";
    },

    handleDynamicRes(payload) {
      this._popUpDialogResult(payload);
    },
    handlePopupDealer(payload){
      this._popUpDialogDealer(payload);
    },
    ...mapMutations("$_yukiChannel", [
      "UPDATE_CHANNEL",
      "SET_RESULT",
      "SUMMARY_RESULT",
      "RESULT_TABLE",
      "LOADINGRESULT",
      "UPDATE_KDPAYOUT",
      "GET_SKIP_COLUMN",
      "GET_SKIP_ROW",
      "GET_SKIP_BETWEEN",
      "UPDATE_DEALER_INFO",
      "POPUP_DIALOG_SCAN_DEALER",
      "RESULT_EVENT",
      "CURRENT_CARD"
    ]),
    ...mapActions("$_yukiChannel", [
      "voidShoe",
      "fetchCurrentChannel",
      "fetchBtnResult",
      "createResult",
      "updateChannel",
      "UpdateChanelStatus",
      "UpdateFinishedCard",
      "UpdateClearCard",
      "resetResult",
      "resetShoe",
      "_popUpDialogResult",
      "_fetchLastResult",
      "_fetchCheckColumExist",
      "_fetchCheckRowExist",
      "_fetchCheckBetweenExist",
      "_fetchGetDealerInfo",
      "submitCardCode",
      "_popUpDialogDealer",
      "fetchCurrentCard"
    ]),

    
  },
};
</script>

<style lang="scss" scoped>

</style>
