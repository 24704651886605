const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getCurrenBankStatement = (state) => state.currentBankStatement;
const getStatement = (state) => {
    return state.statement;
};
const getCountStatement = (state) => {
    return state.countStatement;
};

export default {
    getStatement,
    getCountStatement,
    getLoading,
    getDeleteItems,
    getPopUp,
    getCurrenBankStatement
};