import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    statement: {
        object: [],
    },
    countStatement: {
        objectCount: null,
    },
    currentBankStatement:{
        statement_id: null,
        amount: 0,
        sender_note: null,
        reciever_note: null,
        status: null,
        bank_sender:{
          account_name:null,
          account_number:null,
        }
    },
    loading: false,
    popUp: false,
    deleteItems: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};