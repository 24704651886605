const getSetting = (state) => state.setting;
const getPopUp = (state) => state.popUp;
const getCurrentSetting = (state) => state.currentSetting;
const getDeleteItems = (state) => state.deleteItems;
const getLoading = (state) => state.loading;

export default {
	getLoading,
	getSetting,
	getPopUp,
	getCurrentSetting,
	getDeleteItems,
};
