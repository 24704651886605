import ocStatementSummaryCompanyRp from '@/modules/statementReports/currency/ocStatementSummaryCompanyRp';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryCompanyRp',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryCompanyRp,
        name: 'ocStatementSummaryCompanyRp'
    }]
}];

export default routes;