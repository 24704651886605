/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListRoles = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    const response = await request.post({
        url: 'roles/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_ROLES', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createRole = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'roles',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created role.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created role.`)
};

const updateRole = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'roles/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated role.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated role.`)
};

const deleteRole = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'roles/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted role.`)
    } else vm.$toastr.e(`'failed delete role.`)
};

const fetchPopUp = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
};

const fetchCurrentRole = async({ commit }, data) => {
    const response = await request.get({
        url: 'roles/' + data._id,
        data
    });
    if (response.data.code) {
        commit('UPDATE_CURRENT_ROLE', response.data.data.object);
        commit('UPDATE_SELECT_PERMISSION', response.data.data.object.menu)
    }
};

const fetchRolesWithoutBody = async({ commit }) => {
    const response = await request.post({
        url: 'roles/getAllRolesWithoutBodyField',
    });
    if (response.data.code) {
        commit('UPDATE_ROLES_WITHOUT_BODY', response.data.data);
    }
}

const fetchAllRolesDependOnUser = async({ commit }) => {
    const response = await request.get({
        url: 'roles/getAllRolesDependOnUser',
    });
    if (response.data.code) {
        let role_en_kh =[];
        let role_data = response.data.data.object;
        for(let i  in role_data){
            if(role_data[i].child_role_id == "612a3fcc1a1fcead8871e822" ||
            role_data[i].child_role_id == "612a40a51a1fcead8871e833" || 
            role_data[i].child_role_id == "62c5a1a9f930dbb6007069b7" || 
            role_data[i].child_role_id == "612a40c71a1fcead8871e835" || 
            role_data[i].child_role_id == "63cddbd5b51c1415b8bae1df" || 
            role_data[i].child_role_id == "63cddc81b51c1415b8bae1fd" || 
            role_data[i].child_role_id == "63cddccfb51c1415b8bae208" || 
            role_data[i].child_role_id == "63cddd30b51c1415b8bae213" || 
            role_data[i].child_role_id == "63cddd6fb51c1415b8bae21e" || 
            role_data[i].child_role_id == "63cddde1b51c1415b8bae229" || 
            role_data[i].child_role_id == "6406b857c2fa980eb0174708" || 
            role_data[i].child_role_id == "64095989c2fa980eb0174893" || 
            role_data[i].child_role_id == "64643d791ba4b87724c7158b" || 
            role_data[i].child_role_id == "64643d1f1ba4b87724c7158a" || 
            role_data[i].child_role_id == "61d9a2a869e14b72d45f25d0" || 
            role_data[i].child_role_id == "63f72742fc810f77b4bb30a6" || 
            role_data[i].child_role_id == "640d886e4f7dfc5b74997ee5" || 
            role_data[i].child_role_id == "640d888d4f7dfc5b74997ee6" || 
            role_data[i].child_role_id == "640d889e4f7dfc5b74997ee7" || 
            role_data[i].child_role_id == "640eceb2b994aa79b81a2cc3" || 
            role_data[i].child_role_id == "61bf5eb0c73d7eea8634fbf4" || 
            role_data[i].child_role_id == "648fd726fc595e5604b69ded" || 
            role_data[i].child_role_id == "648fd746fc595e5604b69dee" || 

            role_data[i].child_role_id == "6191db984825e633cefa22d5" || 
            role_data[i].child_role_id == "61829d84e64b1b17688326a3" || 
            role_data[i].child_role_id == "61829d75e64b1b1768832699"|| 
            role_data[i].child_role_id == "61829dbbe64b1b17688326cb" ||
            role_data[i].child_role_id == "6191dc9b4825e6268efa232a" ||
            role_data[i].child_role_id == "61829dd1e64b1b17688326df" ||
            role_data[i].child_role_id == "6191dcc94825e6193ffa2335" ||
            role_data[i].child_role_id == "61829dfee64b1b1768832709" ||
            role_data[i].child_role_id == "6191dce94825e65937fa2340" ||
            role_data[i].child_role_id == "61829da2e64b1b17688326b7" ||
            role_data[i].child_role_id == "6191dc124825e6186dfa2306" ||
            role_data[i].child_role_id == "61829dc7e64b1b17688326d5" ||
            role_data[i].child_role_id == "6191dc364825e6eeebfa2314" ||
            role_data[i].child_role_id == "61829df5e64b1b1768832700" ||
            role_data[i].child_role_id == "6191dc644825e6f57ffa231f" 
            )
            {
                role_en_kh.push(role_data[i]);
            }
        }
        commit('UPDATE_ROLES_DEPEND_ON_USER', role_en_kh);
    }
}

export default {
    fetchListRoles,
    fetchPopUp,
    fetchCurrentRole,
    createRole,
    updateRole,
    deleteRole,
    fetchRolesWithoutBody,
    fetchAllRolesDependOnUser
};
