const getLottoSettingData = (state) => state.lottoSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentlottoSettingData = (state) => state.currentlottoSettingData;


export default {
    getLottoSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentlottoSettingData,
};