<template>
  <div>
    
      <v-container fluid>
        <v-row>

            <v-col cols="4" sm="4" md="4" class="text-left mt-4">
              <v-toolbar-title class=" text-h5 uppercase pl-5 ">REVERSE RESULT</v-toolbar-title>
            </v-col>
            <v-col cols="4" class="text-center text-h5 mt-4">
            <span class=" uppercase">
              Channel is
            </span>
            <span
              :class="[
                channelStatus(channel.is_open).color,
                'uppercase pl-4 channel-status'
              ]"
              
              >
               {{channelStatus(channel.is_open).status }}</span
               > 
              
          </v-col>
          <v-col cols="4" sm="4" md="4" class="text-right pl-5" style="margin-right:0px; margin-top:-15px">
            <v-select
                v-model="channelType"
                :items="items"
                item-value="id" item-text="name"
                label="Channel #  "
                class="channel-type   "
                style="min-width:100%"
                @input="selectChannelType(channelType)"
                solo
            ><template v-slot:prepend>
              <v-icon color="green darken-2">mdi-television</v-icon>
            </template>
            </v-select>

          </v-col>
           
        </v-row>
        <v-row>
            <v-col cols="12"  class="text-left mt-4 pl-5">
              <span class="mx-5 hint"> - To reverse result your channel status must be LIVE <br> </span>
              <span class="mx-5 hint" > - Reverse only last 5 match <br> </span>
           
            </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["channel"],

  data() {
    return {
      valid: true,
      rules: [value => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      canUpdateStatus:true,
      dialogResetResult:false,
      items: [
        {name:"Klaklouk", id:5},
      ],
      channelType : {name : "Klaklouk", id :5}
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
  },
  mounted() {
    if (this.$cookies.get("channel-type")) {
      this.channelType = {name : this.$cookies.get("channel-type"), id : parseInt(this.$cookies.get("channel-type")) };
    }
  },
  methods: {
    selectChannelType(channelType){
      this.$cookies.set("channel-type", channelType);
      this.channelType = {name : this.$cookies.get("channel-type"), id : parseInt(this.$cookies.get("channel-type")) };
      if(parseInt(this.$cookies.get("channel-type")) == 5 ) this.$connectSocket(this.$cookies.get('token'), this.$cookies.get("channel-type"))
      this.$router.go();
    },
    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
          
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text"
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text"
        };
      }
    },

  }
};
</script>

<style scoped>
.channel-status {
  font-weight: 700;
  font-size: 23px;
  padding-top: 0px;
}
.result-image {
  max-width: fit-content;
  max-height: 100%;

  display: block;

  object-fit: contain;
}
.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}
.confirm-btn{
  color: white !important; font-weight:700;
}
.right-input >>> input {
      text-align: center;
      font-size: 24px;
      font-weight: 900;
      color: #FB8C00 !important;
}

.sample>>> input {
      margin-top:5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}
.update-chanel-btn{
  font-size:22px!important;
  font-weight: 700!important;
  margin-top:-7px!important;
}
.update-chanel{
  margin-top: -2px !important;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.hint{
  font-size:16px; opacity:0.7;
}
</style>
