/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListBankUser = async({ commit }, data) => {
    commit('UPDATE_LOADING', true)
    data.sortByFormat.length < 1 ? data.sortBy = '_id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true;
    // console.log(data)
    const response = await request.post({
        url: 'bankAccount/getAll',
        data
    });
    if (response.data.code) {
        // console.log( response.data.data)
        commit('UPDATE_BANK_USER', response.data.data);
        commit('UPDATE_LOADING', false)
    }
};

const createBankUser = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'bankAccount',
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully created Bank User.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail created Bank User.`)
};

const updateBankUser = async({ commit }, { vm, data }) => {
    const response = await request.put({
        url: 'bankAccount/' + data._id,
        data
    });
    if (response.data.code) {
        vm.$toastr.s(`Successfully updated Bank User.`)
        commit('UPDATE_POPUP', false)
    } else vm.$toastr.e(`Fail updated Bank User.`)
};

const deleteBankUser = async({ commit }, { vm, data }) => {
    const response = await request.post({
        url: 'bankAccount/deleteMany',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`successfully deleted Bank User.`)
    } else vm.$toastr.e(`'failed delete Bank User.`)
};

const fetchPopUpBankUser = async({ commit }, payload) => {
    commit('UPDATE_POPUP', payload);
}

const fetchCurrentBankUser = async({ commit }, payload) => {
    commit('UPDATE_CURRENT_BANK_USER', payload);
};

const fetchParentBankUser = async({ commit }) => {
    const response = await request.get({
        url: 'bank/getAllBank',
    });
    if (response.data.code) {
        commit('UPDATE_PARENT_BANK_USER', response.data.data);
    }
}
export default {
    fetchListBankUser,
    fetchPopUpBankUser,
    createBankUser,
    updateBankUser,
    deleteBankUser,
    fetchCurrentBankUser,
    fetchParentBankUser
};
