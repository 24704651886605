const headerCompany = [
  {
    text: 'No',
    align: 'start',
    value: 'no',
  },
    { text: 'View', align: 'start', value: 'actions', sortable: false },
    { text: 'App', value: '_id.app_id' },
    { text: 'COMPANY', value: '_id.com' },
    { text: 'Senior', value: '_id.admin' },
    { text: 'Balance', value: '_id.con_balance' },
    { text: 'Amount', value: 'amount' },
    { text: 'AmountWin/Lose', value: 'amount_win' },
  ]

export default headerCompany;

