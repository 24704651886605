const getYukiSettingData = (state) => state.yukiSettingData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getPopUpPercent = (state) => state.popUpPercent;

const getCurrentYukiSettingData = (state) => state.currentYukiSettingData;


export default {
    getYukiSettingData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getPopUpPercent,
    getCurrentYukiSettingData,
};