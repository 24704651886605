const getBankUser = (state) => state.bankUser;
const getCurrentBankUser = (state) => state.currentBankUser;
const getPopUp = (state) => state.popUp
const getDeleteItems = (state) => state.deleteItems
const getParentBankUser = (state) => state.bankUserParent
const getLoading = (state) => state.loading

export default {
	getBankUser,
	getCurrentBankUser,
	getPopUp,
	getDeleteItems,
	getParentBankUser,
	getLoading
};
