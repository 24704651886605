import treeStatementRp from '@/modules/statementReports/channel/treeStatementRp';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/treeStatementRp',
		component: Layout,
		meta: {
			permission: 'treeStatementRp',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				component: treeStatementRp,
				name: 'treeStatementRp'
			}
		]
	}
];

export default routes;
