/* eslint-disable no-unused-vars */
import Request from '@/services/axios'


let request = new Request;

const fetchListStatement = async ({ commit }, data) => {
    const response = await request.post({
        url: 'cock-whitelist/getAll',
        data
    });
    console.log('response:', response.data);
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }
}


const fetchGetAllConfigUser = async ({ commit }, data) => {
   
    const response = await request.post({
        url: 'limitBetting/getAll',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_DATA', response.data.data);
    }
};
const fetchGetConfigUserByUserId = async ({ commit }, d) => {
    let data = { "user_id": d }
    const response = await request.post({
        url: 'configUser/getConfigUserByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CONFIG_USER_BY_ID', response.data.data);
    }
};


const createBannerData = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'cock-whitelist/create',
        data
    });
    if (response.data.code) {
        commit('LOADING', false);
        commit('UPDATE_BANNER_DATA', response.data.data);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};



const updateLimit = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.post({
        url: 'dealer/update',
        data
    });
    
    if (response.data.code) {
        commit('UPDATE_BANNER_DATA', response.data.data);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }

};

const fetchPopUp = async ({commit}, payload) => {
    commit('UPDATE_POPUP', payload);
};
const fetchCurrentConfigData = async ({commit}, payload) => {
    commit('UPDATE_CURRENT_DATA', payload);
};

const fetchListUsers = async ({commit}, data) => {
    const response = await request.post({
        url: 'users/getAllByUserId',
        data
    });
    if (response.data.code) {
        commit('UPDATE_USERS', response.data.data.object);
    }
};
const deleteData = async ({commit}, {vm, data}) => {
    const response = await request.post({
        url: 'cock-whitelist/remove',
        data
    });
    if (response.data.code) {
        commit('DELETE_ITEMS', [])
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};
export default {
    fetchListStatement,
    createBannerData,
    updateLimit,
    fetchPopUp,
    fetchCurrentConfigData,
    fetchListUsers,
    deleteData,
    fetchGetConfigUserByUserId
};