const UPDATE_LOTTO_SETTIN_DATA = (state, data) => {
    state.lottoSettingData = data;
}
const LOADING = (state, data) => {
    state.loading = data;
}
const UPDATE_CURRENT_DATA = (state, data) => {
   state.currentlottoSettingData = data

};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentlottoSettingData = {
            _id: null,
            name: null,
            is_enable: null,
            is_start: null,
            user_id: null,
            percentage: 0,
            win_lose_type: "1",
            updatedAt: null
        }
    }
    state.popUp = data;
};

const UPDATE_POPUP_PERCENT = (state, data) => {
    if (!data) {
        state.currentlottoSettingData = {
            _id: null,
            name: null,
            is_enable: null,
            is_start: null,
            user_id: null,
            percentage: 0,
            win_lose_type: "1",
            updatedAt: null
        }
    }
    state.popUpPercent = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};

export default {
    UPDATE_LOTTO_SETTIN_DATA,
    LOADING,
    UPDATE_POPUP,
    UPDATE_POPUP_PERCENT,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};