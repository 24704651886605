import ocStatementSummaryPlayerVn from '@/modules/statementReports/currency/ocStatementSummaryPlayerVn';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryPlayerVn',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryPlayerVn,
        name: 'ocStatementSummaryPlayerVn'
    }]
}];

export default routes;