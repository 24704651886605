import yukiSetting from '@/modules/slotMachineSetting/yukiSetting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/yuki-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'yukiSetting',
				component: yukiSetting
			}
		]
	}
];

export default routes;
