import historyByFightDragonReport from '@/modules/historyByFightDragonReport';
import Layout from '@/layouts/default';

const routes = [{
    path: '/historyByFightDragonReport',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: historyByFightDragonReport,
        name: 'historyByFightDragonReport'
    }]
}];

export default routes;