const headerAgent = [
    
    {
      text: 'No',
      align: 'start',
      value: 'no',
    },
    { text: 'View', align: 'start', value: 'actions', sortable: false },
    { text: 'App', value: '_id.app_id' },
    { text: 'Company', value: '_id.com' },
    { text: 'Senior', value: '_id.admin' },
    { text: 'Master', value: '_id.master' },
    { text: 'Agent', value: '_id.agent' },
    { text: 'Balance', value: '_id.agent_balance' },
    { text: 'Amount', value: 'amount' },
    { text: 'AmountWin/Lose', value: 'amount_win' },]

export default headerAgent;

