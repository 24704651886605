import playerWhiteList from '@/modules/playerWhiteList';
import Layout from '@/layouts/default';

const routes = [{
    path: '/playerWhiteList',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: playerWhiteList,
        name: 'playerWhiteList'
    }]
}];

export default routes;