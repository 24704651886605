<template>
  <div>
    <v-container fluid>
      <v-chip :color="correctColorPosition(dealerInfo.position)" class="dealer-info px-4"> {{ dealerInfo.position ==
    'pr' ? "Supervisor" : dealerInfo.position
}}</v-chip>
      <v-row class="pt-5">
        <p class="dealer-name pl-2">{{ dealerInfo && dealerInfo.name ? "Name: " + dealerInfo.name : '---' }}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.last_login ?
    "Last Login: " + moment(dealerInfo.last_login).format('DD/MM/YYYY hh:mm') : '---'
}}</p>
        <p class="dealer-name">{{ dealerInfo && dealerInfo.barcode ?
    'Id: ' + dealerInfo.barcode : 'id: ---'
}}</p>
        <v-btn @click="_openDialogScanDealer(true)" :loading="loadingDealerButton" :disabled="loadingDealerButton"
          color="blue-grey" class="white--text dealer-changer">
          Change Dealer
          <v-icon right dark>
            mdi-cloud-sync
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>

        <!-- <v-col cols="5" class="live-channel-info-container">
          <iframe v-if="channel && channel.live_urls" :src="channel.live_urls[0]" frameborder="0"
            width="100%" height="415px" style="padding:0px;overflow:hidden;"></iframe>
        </v-col> -->

        <v-col cols="5" class="live-channel-info-container">
          <div class="result" style="margin-top: 100px;">
          <tr>
            <td class="banker-title">BANKER</td>
            <td class="tie-title">TIE</td>
            <td class="player-title">PLAYER</td>
          </tr>
          <tr>
            <td class="banker-result">{{ currencyFormat(summaryBanker) }} $</td>
            <td class="tie-result">{{ currencyFormat(summaryTie) }} $</td>
            <td class="player-result">{{ currencyFormat(summaryPlayer) }} $</td>
          </tr>
        </div>
          <!-- <v-row justify="space-around">
            <v-row class="type_player pt-5">
                <p class="tiger-title">BANKER</p>
                <p class="dragon-title">PLAYER</p>
            </v-row>
              <v-avatar color="red" size="200" class="banker-avatar">
                <span class="text-h2" style="color:white;">{{ currencyFormat(summaryBanker) }} $</span>
              </v-avatar>

              <v-avatar color="blue" size="200" class="player-avatar">
                <span class="text-h2" style="color:white;">{{ currencyFormat(summaryPlayer) }} $</span>
              </v-avatar>

              <v-avatar color="green" size="200" class="tie-avatar">
                <span class="text-h2" style="color:white;">{{ currencyFormat(summaryPlayer) }} $</span>
              </v-avatar>
          </v-row> -->

        </v-col>

        <v-col cols="2" class="game-control-container">
          <p
            :class="socketChannelCountdown && socketChannelCountdown <= 10 ? 'red--text uppercase default-count-down' : 'uppercase default-count-down'">
            {{ socketChannelCountdown ? socketChannelCountdown : defaultCountDown }}
          </p>
          <p :class="[
  channelStatus(channel.is_open).color,
  'uppercase channel-status',
]">
            {{ channelStatus(channel.is_open).status }}
          </p>
          <div style="width:100%; text-align:center;">
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" color="error"
              class="mt-5" style="width:150px" @click="alertResetResultDialog" :disabled="
  channel.is_first_game == false
    ? true
    : false
">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Shoe
            </v-btn>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" :disabled="
  channel.is_first_game == false
    ? true
    : false
" color="error" class="ma-2 white--text mt-4" style="width:150px" @click="alertResetShoeDialog">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Score
            </v-btn>
            <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" :disabled="
  loading == true ||
    channel.is_open == true
    ? true
    : false
" color="error" class="ma-2 white--text mt-4" style="width:150px" @click="_clearCard">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Clear Card
            </v-btn>
            <br>
            <v-btn v-if="(dealerInfo && dealerInfo.position == 'pr' || dealerInfo.position == 'pitbox')" :disabled="
  loading == true ||
    channel.is_open == true
    ? true
    : false
" color="error" class="ma-2 white--text mt-4" style="width:150px" @click="alertVoidShoeDialog">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Void Shoe
            </v-btn>
          </div>
        </v-col>
        <v-col cols="5">
          <v-col cols="12">
            <v-text-field :autofocus="true" type="number" :disabled="!dealerInfo._id || !allowInput || getAllowScanCard"
              label="Text Input barcode" ref='search' autocomplete="off" v-model="scanCard" onpaste="return false">
            </v-text-field>
          </v-col>
          <v-row style="width: 100%;">
            <v-col cols="6" class="player-card-container" style="background-color:#ff7b7b">

              <v-row class="type_player pt-5">
                <p class="type-player-title">Banker</p>
                <p class=" type-player-title  px-3">|</p>
                <p class="type-player-title">{{ totalBanker }} </p>
              </v-row>

              <v-row style="">
                <v-col cols="6">
                  <v-img :src="'images/baccarat/' + csBanker2" class="stand-card-player ">
                  </v-img>
                </v-col>
                <v-col cols="6">
                  <v-img :src="'images/baccarat/' + csBanker1" class="player-stand-card">
                  </v-img>
                </v-col>
              </v-row>
              <v-col cols="12" style=" position:relative">
                <v-img :src="'images/baccarat/' + csBanker3" class="player-vertical-card">
                </v-img>
              </v-col>
            </v-col>
            <v-col cols="6" class="dealer-card-container ml-0" style="background-color:#00b4d8;">
              <v-row class="type_player pt-5 ">
                <p class="type-player-title">Player</p>
                <p class=" type-player-title px-3">|</p>
                <p class="type-player-title">{{ totalPlayer }} </p>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-img :src="'images/baccarat/' + csPlayer2" class="stand-card-dealer">
                  </v-img>
                </v-col>
                <v-col cols="6">
                  <v-img :src="'images/baccarat/' + csPlayer1" class="dealer-stand-card">
                  </v-img>
                </v-col>
              </v-row>
              <v-col cols="12" style=" position:relative">
                <v-img :src="'images/baccarat/' + csPlayer3" class="dealer-vertical-card ">
                </v-img>
              </v-col>
            </v-col>
          </v-row>
          <!-- <v-row style="width: 100%;">
            <v-col cols="6" class="total-amount" style="background-color:#ff7b7b">
              <p v-if="socketChannelCountdown<=10">Total = {{ getCurrentTotal }} $</p>
              <p v-else>Total = 0 $</p>
            </v-col>
            <v-col cols="6" class="total-amount" style="background-color:#00b4d8;">
              <p v-if="socketChannelCountdown<=10">Total = {{ getCurrentTotal }} $</p>
              <p v-else>Total = 0 $</p>
            </v-col>
          </v-row> -->
          <div v-if="channel.is_last_game">
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:60px; text-align: right;">
              <v-btn class="update-chanel-btn" @click="_finishedCardBaccarat"
                :color="channelUpdateStatus(channel.is_open).background"
                :disabled="(channel.is_first_game == true || channel.is_open == false || loadingResult ? true: false)">
                Finish
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div v-if="dealerInfo && dealerInfo.position == 'dealer'" style="margin-top:60px;text-align: right;">
              <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
                @click="_updateChanelStatusBaccarat"
                :disabled="channel.is_open == false || loadingResult ? true: false || !canUpdateStatus || Boolean(socketChannelCountdown && socketChannelCountdown > 0)">
                {{ channelUpdateStatus(channel.is_open).status }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      
    </v-container>

    <v-dialog v-model="dialogResetShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Score ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetShoe">
            Yes, Reset Score
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVoidShoe" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Score ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogVoidShoe = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_voidShoe">
            Yes, Void Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="getDealerDialog" width="420" height="420" persistent>
      <!-- <v-card class="text-right px-5">
        <v-btn class="mx-auto justify-center" color="danger" @click="_openDialogScanDealer(false)"> <span
            style="color:red;"> x</span></v-btn>
      </v-card> -->

      <v-card class="text-center px-5">

        <p class="text-h5 text-danger">Please Scan Card</p>
        <v-text-field autofocus autocomplete="off" label="" type="password" v-model="barcodeDealer"
          onpaste="return false"></v-text-field>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Shoe ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Shoe
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
addEventListener('keypress', (event) => {
  if (event.code == 'NumpadEnter') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._updateChanelStatusBaccarat();
    }
  }
  if (event.code == 'NumpadSubtract') {
    if(window.callInside.loadingResult) {
      return false;
    }
    else{
      window.callInside._finishedCardBaccarat();
    }
  }
  if (event.code == 'NumpadAdd') window.callInside._openDialogScanDealer(true);
  if (event.code == 'NumpadMultiply') window.callInside.increaseColumn();
  // if (event.code == 'NumpadDivide') window.callInside.reloadWindow();
  if (event.code == 'NumpadMultiply') window.callInside.focusSearch();

});
window.onkeydown = function (e) {
  return !(e.keyCode == 32 && e.target == document.body);
};
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  props: [
    "channel",
    "objSnNumber",
    "socketChannelType",
    "socketChannelCountdown",
    "dealerInfo",
    "totalBanker",
    "totalPlayer",
    "csBanker3",
    "csBanker2",
    "csBanker1",
    "csPlayer3",
    "csPlayer2",
    "csPlayer1",
    "getDealerDialog",
    "getResultEvent",
    "objCoResult",
    "getAllowScanCard",
    "summaryBanker",
    "summaryPlayer",
    "summaryTie",
  ],

  data() {
    return {
      // imgPath: process.env.VUE_APP_API_REPORT_URL,
      // defaultPic: 'images/defaultImg.jpg',
      valid: true,
      rules: [(value) => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      updateChanel: [],
      loadingDealerButton: false,
      canUpdateStatus: true,
      dialogResetResult: false,
      dialogResetShoe: false,
      dialogVoidShoe: false,
      scanCard: '',
      barcodeDealer: "",
      defaultCountDown: 0,
      allowInput: true,
      rule_meron_percentage: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.wala_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target" + this.objKdPayout.target_percentage;
      }],
      rule_wala_percentage: [(v) => {
        if (
          v > 0 &&
          v <= this.objKdPayout.target_percentage - this.objKdPayout.meron_percentage &&
          !!v
        )
          return true;
        return "Number has to be bigger than 0 and smaller or equal target " + this.objKdPayout.target_percentage;
      },]
    };
  },
  computed: {

    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_baccaratChannel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  watch: {
    csPlayer1: function (newV) {
      if (newV) this.scanCard = '';
    },
    csPlayer2: function (newV) {
      if (newV) this.scanCard = '';
    },
    csPlayer3: function (newV) {
      if (newV) this.scanCard = '';
    },
    csBanker1: function (newV) {
      if (newV) this.scanCard = '';
    },
    csBanker2: function (newV) {
      if (newV) this.scanCard = '';
    },
    csBanker3: function (newV) {
      if (newV) this.scanCard = '';
    },
    dealerInfo: function () {
      this.$nextTick(() => this.$refs.search.focus())
    },
    getDealerDialog: function (newV) {
      if (newV == false) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    barcodeDealer: function (newV) {
      if (newV.length == 8) {
        this._submitDealerBarcode(newV);
      }

    },
    scanCard: function (newV) {
      if (newV.length == 4) {
        this._submitCardcode(newV);
        // this.scanCard = '';
      }
      if (newV.length > 4) {
        this.scanCard = '';
      }
      if (newV == 2888) {
        this.allowInput = false;
        setTimeout(() => {
          this.allowInput = true;
          this.$nextTick(() => this.$refs.search.focus());
        }, 1200);
        this.scanCard = '';
      }
    },
    getCurrentTotal: function () { },
    socketChannelType: function () { },
    socketChannelCountdown: function (newV) {
      if(newV == 0)
      this.$nextTick(() => this.$refs.search.focus());
    },
    loading: function () { },
    loadingResult: function () { },
    channel: function (newV) {
      if (newV.is_open == true) this.resetCard();
    }
  },
  async created() {
    await this.initialDealer();


  },
  async mounted() {
    window.callInside = this;

  },
  methods: {
    async initialDealer() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        await this._submitDealerBarcode(dealerId.barcode)
      }
      else this._openDialogScanDealer(true);
    },
    moment,
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    alertResetShoeDialog() {
      this.dialogResetShoe = true;
    },
    alertVoidShoeDialog() {
      this.dialogVoidShoe = true;
    },
    reloadWindow(){
      window.location.reload();
    },
    focusSearch(){
      this.$nextTick(() => this.$refs.search.focus());
    },
    _resetShoe() {
      this.$emit("submitResetShoe");
      this.dialogResetShoe = false;
    },
    _voidShoe() {
      this.$emit("submitVoidShoe");
      this.dialogVoidShoe = false;
    },
    _submitDealerBarcode(barcode) {
      this.$emit("submitBarcodeDealer", barcode);
      this.barcodeDealer = '';

    },
    _submitCardcode(cardCode) {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer')
      {
        if(cardCode != ''){
            this.$emit("submitCardCode", cardCode);
          this.allowInput = false;
          setTimeout(() => {
            this.allowInput = true;
            this.$nextTick(() => this.$refs.search.focus());
          }, 1200);
          this.scanCard = '';
        }
      }
        

      // this.scanCard = '';
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
      }
    },

    _openDialogScanDealer(payload) {
      this.barcodeDealer = '';
      this.$emit("popUpDealer", payload);
    },

    _openDialogResult() {
      this.$emit("submitDynamicRes", true);
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "CLOSE",
          color: "red--text",
        };
      }
    },

    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },
    increaseColumn() {
      this.channel.column = this.channel.column + 1;
      this.$emit("submitUpdateChanel", this.channel);
    },
    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult() {
      // this.confirmDialog = true;
    },

    async _updateChanelStatusBaccarat() {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer')
        if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 0)) == false) {
          this.$emit("submitUpdateStatus", this.resultData);
          this.canUpdateStatus = false;
          this.$nextTick(() => this.$refs.search.focus());
          setTimeout(this.letUpdateChanelStatus, 3000);
        }

    },
    async _finishedCardBaccarat() {
      if (this.dealerInfo && this.dealerInfo.position == 'dealer'){
        if(this.channel.is_last_game == true){
            if ((this.channel.is_open == false || !this.canUpdateStatus || Boolean(this.socketChannelCountdown && this.socketChannelCountdown > 2)) == false) {
            this.$emit("submitFinishedCard", this.resultData);
            this.canUpdateStatus = false;
            this.$nextTick(() => this.$refs.search.focus());
            setTimeout(this.letUpdateChanelStatus, 3000);
          }
        }
        
      }
      this.scanCard = '';
    },
    //_finishedCardBaccarat
    async _clearCard() {
      if (this.dealerInfo && this.dealerInfo.position == 'pr' || this.dealerInfo.position == 'pitbox')
        if (this.channel.is_open == false || this.channel.is_open == null) {
          this.$emit("submitClearCard", this.resultData);
        }
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return parseInt(num).toLocaleString("en");
    },

    _preventCardInput() {
      this.allowInput = false;
    },
    _allowCardInput() {
      this.allowInput = true;
    },

    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    correctColorPosition(position) {
      switch (position) {
        case 'dealer':
          return 'error';
        case 'pr':
          return 'primary';
        case 'pitbox':
          return 'success';
        default:
          break;

      }
    },

    ...mapMutations("$_baccaratChannel", ["LOADING", "UPDATE_KDPAYOUT"]),
    ...mapActions("$_baccaratChannel", ["resetCard"]),
  },
};
</script>

<style scoped>
.banker-result{
  font-size: 50px;
  background: red;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 35px;
  text-align: center;
}
.tie-result{
  font-size: 50px;
  background: rgb(77, 222, 77);
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.player-result{
  font-size: 50px;
  background: blue;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.banker-title{
  font-size: 35px;
  background: red;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.tie-title{
  font-size: 35px;
  background: rgb(77, 222, 77);
  color: white;
  padding-left: 45px;
  padding-right: 45px;
  text-align: center;
}
.player-title{
  font-size: 35px;
  background: blue;
  color: white;
  padding-left: 17px;
  padding-right: 17px;
  text-align: center;
}
.tiger-title{
  padding-right: 160px;
  padding-top: 20px;
  font-size: 25px !important;
  font-weight: 800;
  color: red;
}
.dragon-title{
  padding-top: 20px;
  font-size: 25px !important;
  font-weight: 800;
  color: blue;

}
.banker-avatar{
    height: 150px;
    min-width: 150px;
    width: 150px;
    margin-right: 275px;
    margin-top: 50px;
}
.player-avatar{
    height: 150px;
    min-width: 150px;
    width: 150px;
    margin-left: 225px;
    margin-top: -200px;
}
.tie-avatar{
    height: 150px;
    min-width: 150px;
    width: 150px;
    margin-left: 225px;
    margin-top: -150px;
}
.default-count-down {
  font-size: 60px !important;
  letter-spacing: 9px !important;
  font-weight: 900 !important;
  text-align: center;
  text-shadow: 3px 0px black;
  margin-top: 30px;
}

.count-number-container {
  justify-content: right;
  margin-top: 10px;
  text-shadow: 5px 1px black;
}

/* .channel-status {
  font-weight: 900;
  font-size: 45px;
  padding-top: 0px;

  text-align: center;
} */
.channel-status {
  font-weight: 900;
  font-size: 40px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
  text-align: center;
  margin-top: 60px;
}

.green--text {
  text-shadow: 3px 0px green !important;
}

.red--text {
  text-shadow: 3px 0px red !important;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385px;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.green-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #013220 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}

.dealer-photo {
  width: 45%;
  height: 190px;
  object-fit: cover;
  border-radius: 3px;
}

.live-channel-info-container {
  width: 100%;
  height: 440px;
  border: 1px solid black;
  border-radius: 3px;
  position: relative;
  margin-top: 70px;
}

.game-control-container {
  margin-top: 50px;
  padding-left: 15px
}

.dealer-name {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  margin-bottom: -40px;
  padding-left: 5px;
}
.total-amount {
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  color: white;
}

.dealer-dob {
  font-size: 18px;
  text-align: center;
}

.dealer-last-login {
  font-size: 18px;
  text-align: center;
}

.dealer-changer {
  margin-top: -5px;
  margin-left: 5px;
  margin-bottom: -50px
}

.player-card-container {
  height: 330px;
  border-right: 1px solid black;
  text-align: center;
  padding: 0px
}

.dealer-card-container {
  height: 330px;
  border-left: 1px solid black;
  text-align: center;
  padding: 0px;
}

.player-stand-card {
  /* margin-left: 20px;
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px; */
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
  margin-left: -10px
}

.stand-card-player {
  margin-left: 10px;
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
  /* width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
  margin-left: 0px */
}

.stand-card-dealer {
  /* width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
  margin-left: -5px */
  margin-left: 10px;
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
}

.player-vertical-card {
  width: 100px;
  height: 135px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 32%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-vertical-card {
  width: 100px;
  height: 135px;
  margin-top: -10px;
  transform: rotate(270deg);
  position: absolute;
  left: 30%;
  border: 1px solid;
  border-radius: 5px;
}

.dealer-info {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: -7px;
  text-transform: uppercase;

}

.dealer-stand-card {
  /* margin-left: 15px;
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px; */
  width: 100px;
  height: 135px;
  border: 1px solid;
  border-radius: 5px;
  margin-left: -10px
}

.type_player {
  justify-content: center !important;
  width: 100%;
}

.type-player-title {
  font-size: 20px !important;
  font-weight: 700;
  color: white;

}
</style>
