<template>
    <v-flex class="login-logo-section">
        <div class="m-auto text-center pt-15 mt-0">
            <!-- <h1>Welcome To aa2288.net</h1> -->
            <!-- <img class="hero-img img-fluid mb-5" src="/images/logo.png" style="border-radius:100%;  margin:auto!important"> -->
            <!-- <div class="font-weight-bold header-text text-left px-2" style="margin-top:-100px;">
                <img class="img-fluid login-logo-footer" src="//static3.goperya.com/landing/img/slogan-lg.png?v=10.60" style=" margin:auto!important">
            </div> -->
        </div>
    </v-flex>
</template> 
<script>
import "@/assets/css/base.css";
import "@/assets/css/login.css";
export default {

}
</script>