import ocStatementSummaryPlayerRp from '@/modules/statementReports/currency/ocStatementSummaryPlayerRp';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryPlayerRp',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryPlayerRp,
        name: 'ocStatementSummaryPlayerRp'
    }]
}];

export default routes;