const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_CURRENT_BANK_STATEMENT = (state, data) => {
   state.currentBankStatement = data
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentBankStatement = {
            _id: null,
            amount: 0,
            sender_note: null,
            reciever_note: null,
            status: null,
            bank_sender:{
            account_name:null,
            account_number:null,
            }
        }
    }
    state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};
const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};
const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_BANK_STATEMENT,
    DELETE_ITEMS
};