import han2Ch20Channel from '@/modules/channelGames/han2Ch20Channel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/han2Ch20Channel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: han2Ch20Channel,
        name: 'han2Ch20Channel'
    }]
}];

export default routes;