<template>
  <div>
    <v-layout class="table-result">
      <!-- <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
        <tr v-for="r in 3" :key="r">
          <td v-for="c in channel.column" :key="c" style="padding:0px; margin:0px">
            <div v-if="c > channel.column-channel.fix_column" class="td-table-style">
              <button :class="matchResult(r, c).color +' text-center'"
                      :disabled="!channel.is_open == null || !matchResult(r, c).fight_no "
                      @click="_select(r, calculateColumn(c), matchResult(r, c).ch_id)"><div class="allow justify-center text-center fight-no-style">{{matchResult(r, c).fight_no}}</div></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table> -->
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          
          <tr v-if="orderResult.length > 0">
            <td  style="height:60px !important; width:60px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                 #
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P1</span>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P2</span>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P3</span>
              </div>  
            </td>  
            <td v-for="(result, i) in orderResult" :key="i" style="height:60px !important; width:60px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.fight_no" height="50px"> {{result.fight_no }} </span>
              </div>
              <div class="selectp1" style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;" @click="selectP1(result)">
                <v-avatar
                    :color="switchResult(result.result1_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result1_name) }}</span>
                </v-avatar>
              </div>                         
              <div class="selectp2" style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;" @click="selectP2(result)">
                <v-avatar
                    :color="switchResult(result.result2_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result2_name) }}</span>
                </v-avatar>
              </div> 
              <div  class="selectp3" style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;" @click="selectP3(result)">
                <v-avatar
                    :color="switchResult(result.result3_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result3_name) }}</span>
                </v-avatar>
              </div>  
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
    <v-layout class="py-5" justify-center>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 1'] ?objSummaryCoResult['Banker 1']: 0 }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 2']?objSummaryCoResult['Banker 2']:0  }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 3']?objSummaryCoResult['Banker 3']:0  }}
        </span>
      </v-avatar>

      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 1']?objSummaryCoResult['Player 1']:0 }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 2']?objSummaryCoResult['Player 2']:0 }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 3']?objSummaryCoResult['Player 3']:0 }}
        </span>
      </v-avatar>
      <v-avatar class="mx-5" color="grey" size="56">
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['CANCEL'] }}
        </span>
      </v-avatar>
    </v-layout>
    <!--  dialog result1  -->
    <v-dialog v-model="dialogSelectResult1" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_1">
            <btn-result :value="'615d4a899e9ab44fe23eb9db'" :color="'cir-red'" />
            <btn-result :value="'615d4aa59e9ab44fe23eb9dd'" :color="'cir-blue'" />
            <btn-result :value="'615d5a759e9ab44fe23eba43'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirmP1=true">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogConfirmP1 -->
    <v-dialog
        v-model="dialogConfirmP1"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="result_id_1 == '615d4a899e9ab44fe23eb9db'" class="meron-text bold">Banker 1</span> 
              <span v-if="result_id_1 == '615d4aa59e9ab44fe23eb9dd'" class="meron-text bold">Player 1</span> 
              <span v-if="result_id_1 == '615d5a759e9ab44fe23eba43'" class="cancel-text bold">Cancel</span> 
            <br>
            </div>
           <div :class=" result_id_1 == '615d4a899e9ab44fe23eb9db' ? ' confirm-circle meron' 
           :result_id_1 == '615d4aa59e9ab44fe23eb9dd'?' confirm-circle wala'
           :result_id_1 == '615d5a759e9ab44fe23eba43'?' confirm-circle cancel'
           :''">
           </div>
          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirmP1 = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResultP1"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!--  dialog result2  -->
    <v-dialog v-model="dialogSelectResult2" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_2">
            <btn-result :value="'648fb0075284f65204c379a3'" :color="'cir-red'" />
            <btn-result :value="'648fb01c5284f65204c379a4'" :color="'cir-blue'" />
            <btn-result :value="'6491183b222ff55fcc00fcdb'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirmP2=true">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialogConfirmP2 -->
    <v-dialog
        v-model="dialogConfirmP2"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="result_id_2 == '648fb0075284f65204c379a3'" class="meron-text bold">Banker 2</span> 
              <span v-if="result_id_2 == '648fb01c5284f65204c379a4'" class="meron-text bold">Player 2</span> 
              <span v-if="result_id_2 == '6491183b222ff55fcc00fcdb'" class="cancel-text bold">Cancel</span> 
            <br>
            </div>
            <div :class=" result_id_2 == '648fb0075284f65204c379a3' ? ' confirm-circle meron' 
                 :result_id_2 == '648fb01c5284f65204c379a4'?' confirm-circle wala'
                 :result_id_2 == '6491183b222ff55fcc00fcdb'?' confirm-circle cancel'
                 :''">
           </div>
          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirmP2 = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResultP2"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <!--  dialog result3  -->
    <v-dialog v-model="dialogSelectResult3" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_3">
            <btn-result :value="'648fb0655284f65204c379a6'" :color="'cir-red'"/>
            <btn-result :value="'648fb0805284f65204c379a7'" :color="'cir-blue'"/>
            <btn-result :value="'64911845222ff55fcc00fcdc'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn>
          <v-btn color="primary" @click="dialogConfirmP3=true">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogConfirmP3"
        persistent
        max-width="580"
        max-height="580"
      >
        <v-card class="text-center">
          <v-card-title class="text-h6 justify-center my-5">
            <div class="w-100" style="width:100%">
              Do you want to submit 
              <span v-if="result_id_3 == '648fb0655284f65204c379a6'" class="meron-text bold">Banker 2</span> 
              <span v-if="result_id_3 == '648fb0805284f65204c379a7'" class="meron-text bold">Player 2</span> 
              <span v-if="result_id_3 == '64911845222ff55fcc00fcdc'" class="cancel-text bold">Cancel</span> 
            <br>
            </div>
            <div :class=" result_id_3 == '648fb0655284f65204c379a6' ? ' confirm-circle meron' 
                 :result_id_3 == '648fb0805284f65204c379a7'?' confirm-circle wala'
                 :result_id_3 == '64911845222ff55fcc00fcdc'?' confirm-circle cancel'
                 :''">
           </div>
          </v-card-title>
          <v-card-actions>

            <v-btn
              color="grey darken-1"
              class="cancel-btn"
              @click="dialogConfirmP3 = false"
            >
              No, Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="agree-btn"
              @click="saveResultP3"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters } from "vuex";
export default {
  components: {BtnResult},
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      dialogSelectResult1: false,
      dialogSelectResult2: false,
      dialogSelectResult3: false,
      dialogConfirmP1:false,
      dialogConfirmP2:false,
      dialogConfirmP3:false,
      result_id_1:null,
      result_id_2:null,
      result_id_3:null,
      defineResult: {
        bet_type_id:null,
        result_id: null,
        type:null
      },
    }
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    }
  },
  mounted(){
    
  },
  computed: {
    orderResult() {
      return this.getHan2Results();
    },

...mapGetters("$_reverseResultHan2", {
  loadingResult: "getLoadingResult",
}),
},
 
  methods: {
    saveResultP1() {
        this.defineResult.bet_type_id = this.result_id_1;
        this.defineResult.type = 1;
        this.dialogConfirmP1 = false
        this.dialogSelectResult1= false,
        this.$emit("submitResult", this.defineResult);
        if(this.loadingResult == false) {
            // this.dialogConfirmP1 = false
            // this.dialogSelectResult1= false,
            this.result_id_1 = null;
            this.defineResult= {
              bet_type_id:null,
              result_id: null,
              type:null
            };
          }
    },
    saveResultP2() {
        this.defineResult.bet_type_id = this.result_id_2;
        this.defineResult.type = 2;
        this.dialogConfirmP2 = false
        this.dialogSelectResult2= false,
        this.$emit("submitResult", this.defineResult);
        if(this.loadingResult == false) {
            // this.dialogConfirmP2 = false
            // this.dialogSelectResult2= false,
            this.result_id_2 = null;
            this.defineResult= {
              bet_type_id:null,
              result_id: null,
              type:null
            };
          }
    },
    saveResultP3() {
        this.defineResult.bet_type_id = this.result_id_3;
        this.defineResult.type = 3;
        this.dialogConfirmP3 = false
        this.dialogSelectResult3= false,
        this.$emit("submitResult", this.defineResult);
        if(this.loadingResult == false) {
            // this.dialogConfirmP3 = false
            // this.dialogSelectResult3= false,
            this.result_id_3 = null;
            this.defineResult= {
              bet_type_id:null,
              result_id: null,
              type:null
            };
          }
    },
  
    // cancelResult() {
    //   this.defineResult.rid = null
    //   this.defineResult.cid = null
    //   this.defineResult.result_code = null
    //   this.dialogResult = false
    // },
    calculateColumn(c) {
      if (this.channel.column - this.channel.fix_column > 0) {
        return c + (this.channel.column - this.channel.fix_column)
      } else {
        return c;
      }
    },
    selectP1(result){
      this.defineResult.result_id = result._id;
      this.dialogSelectResult1 = true;
    },
    selectP2(result){
      this.defineResult.result_id = result._id;
      this.dialogSelectResult2 = true;
    },
    selectP3(result){
      this.defineResult.result_id = result._id;
      this.dialogSelectResult3 = true;
    },
    cancelSelectResult(){
      this.dialogSelectResult1 = false;
      this.dialogSelectResult2 = false;
      this.dialogSelectResult3 = false;
    },
    getHan2Results() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.objCoResult !== null){
          let resultList = this.objCoResult;
         
          for (var index = resultList.length - 1; index >= 0; index--) {
            const item = resultList[index];
            if(row <= this.rows){
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }else{
              row = 1
              col += 1
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }
          }
        }
        return newResult;
    },
    switchResult(name) {
      let data;
      switch (name) {
        case 'Banker 1':
          data = "#b31013";
          return data;
        case 'Banker 2':
          data = "#b31013";
          return data;
        case 'Banker 3':
          data ="#b31013";
          return data;
        case 'Player 1':
          data = "#0b4592";
          return data;
        case 'Player 2':
          data = "#0b4592";
          return data;
        case 'Player 3':
          data = "#0b4592";
          return data;
        case 'CANCEL':
          data = "#9e9e9e";
          return data;
      }
    },
    switchName(name) {
      let data;
      switch (name) {
        case 'Banker 1':
          data = "B1";
          return data;
        case 'Banker 2':
          data = "B2";
          return data;
        case 'Banker 3':
          data ="B3";
          return data;
        case 'Player 1':
          data = "P1";
          return data;
        case 'Player 2':
          data = "P2";
          return data;
        case 'Player 3':
          data = "P3";
          return data;
        case 'CANCEL':
          data = "C";
          return data;
      }
    },
  }
}
</script>

<style scoped>
.table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
.allow:hover {
  color: gold;
  font-weight: bolder;
    font-size: 29px;
    margin-top: -21px;

}
.selectp1:hover {
  cursor: pointer;
}
.selectp2:hover {
  cursor: pointer;
}
.selectp3:hover {
  cursor: pointer;
}
</style>
