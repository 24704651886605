var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticStyle:{"text-align":"center","vertical-align":"middle"}},[_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.getUsers ? _vm.getUsers.object : [],"footer-props":_vm.footerProps,"item-key":"_id","id":"table-1","server-items-length":_vm.getUsers ? _vm.getUsers.objectCount.count : null,"page":_vm.data.page,"items-per-page":_vm.data.rowsPerPage,"sort-desc":_vm.data.descendingFormat,"sort-by":_vm.data.sortByFormat,"loading":_vm.getLoading,"value":_vm.getDeleteItems,"mobile-breakpoint":"0"},on:{"update:page":[function($event){return _vm.$set(_vm.data, "page", $event)},_vm.getData],"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.data, "rowsPerPage", $event)},_vm.getData],"update:sortDesc":function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.data, "descendingFormat", $event)},_vm.getData],"update:sortBy":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"update:sort-by":function($event){return _vm.$set(_vm.data, "sortByFormat", $event)},"input":_vm.itemDelete},scopedSlots:_vm._u([{key:`item`,fn:function({ item }){return [_c('tr',{},[_c('td',{staticClass:"col-1 fit"},[_vm._v(" "+_vm._s(item.user_name)+" ")]),_c('td',{staticClass:"col-1 fit"},[_vm._v(" "+_vm._s(item.app_name)+" ")]),_c('td',{staticClass:"col-2 fit"},[_vm._v(" "+_vm._s(item.account_info)+" ")]),_c('td',{staticClass:"col-2 fit"},[_vm._v(" "+_vm._s(item.createdAt)+" ")]),_c('td',{staticClass:"col-1 fit"},[(
                        item && item.role_id === '612a3fcc1a1fcead8871e822'
                      )?_c('span'):_vm._e(),(
                        item &&
                        item.role_id !== '612a3fcc1a1fcead8871e822' &&
                        item.currency_type === 1
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatUsd(item.balance))+" $ ")]):_vm._e(),(
                        item &&
                        item.role_id !== '612a3fcc1a1fcead8871e822' &&
                        item.currency_type === 2
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.currencyFormatKh(item.balance))+" ៛ ")]):_vm._e()]),_c('td',{staticClass:"col-1 fit"},[_vm._v(" "+_vm._s(item.role_name)+" ")]),_c('td',{staticClass:"col-1 fit"},[_c('a',{on:{"click":function($event){return _vm._openDialogListIpUser(item)}}},[_vm._v(" "+_vm._s(item.public_ip)+" ")])]),_c('td',{staticClass:"col-2 fit"},[_c('ActionComponents',{attrs:{"darkmode":_vm.darkmode,"item":item,"dataDelete":_vm.getDeleteItems,"params":_vm.data,"userp":_vm.userp,"user-permission":_vm.userAccessPermission}})],1)])]}},{key:"top",fn:function(){return [_c('Header',{attrs:{"darkmode":_vm.darkmode,"params":_vm.data,"dataDelete":_vm.getDeleteItems,"user-permission":_vm.userAccessPermission}})]},proxy:true}],null,true)})],1),_c('DialogListIpUserComponents',{attrs:{"dialog":_vm.ipDialog,"listData":_vm.listIpUserData},on:{"onCloseDialog":function($event){_vm.ipDialog = !_vm.ipDialog}}}),_c('Dialog',{attrs:{"darkmode":_vm.darkmode,"loading":_vm.loading,"user":_vm.getCurrentUser,"dialog":_vm.getPopUp,"userp":_vm.userp,"optionRoles":_vm.optionRoles,"is-validate":_vm.valid,"listCompany":_vm.getListUserCompany,"listSenior":_vm.getListUserSenior,"listApp":_vm.getListApp,"selectedChannel":_vm.selectedChannel},on:{"submit":_vm.handleSubmit,"handleListCompany":_vm.getListCompany,"handleListSenior":_vm.getListSenior,"onCloseDialog":_vm.closeDialogCreate}}),(this.loader == true)?_c('div',{staticClass:"loader"}):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-header"},[_c('div',{staticClass:"section-header-breadcrumb",attrs:{"hidden":""}},[_c('div',{staticClass:"breadcrumb-item active"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Dashboard")])]),_c('div',{staticClass:"breadcrumb-item"},[_vm._v("User Page")])])])
}]

export { render, staticRenderFns }