<template>
  <div>
    <!-- sort-by="user_name" -->
    <div class="section-header"></div>
    <div class="section-body">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive" style="text-align: center">
            <v-data-table
              :headers="headers"
              :items="getStatement ? getStatement.object : []"
              :server-items-length="getCountStatement.count"
              item-key="_id._id"
              class=" table table-striped"
              id="table-1"
              style="font-family: 'khmer mef1'"
              :footer-props="footerProps"
              :page.sync="data.page"
              :items-per-page.sync="data.rowsPerPage"
              :sort-desc.sync="data.descendingFormat"
              :sort-by.sync="data.sortByFormat"
              @update:page="getData"
              @update:items-per-page="getData"
              @update:sort-desc="getData"
              hide-default-header
              mobile-breakpoint="0"
            >
              <template v-slot:header="{ props }">
                <th v-for="head in props.headers" :key="head._id">
                  {{ head.text }}
                </th>
              </template>
              <template v-slot:top>
                <Header
                  :darkmode="darkmode"
                  :params="data"
                  :user-permission="userAccessPermission"
                  :user="user"
                  :time="time"
                  @child-checkbox="checkboxValue"
                />
              </template>

              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td>{{ getStatement.object.indexOf(item) + 1 }}</td>
                  <td>{{ item.app_id }}</td>
                  <td>{{ item.public_ip }}</td>
                  <td>{{ item.user_name }}</td>
                  <td>{{ currencyFormat(item.balance) }}</td>
                  <td>{{ item.group_no }}</td>
                  <td
                    class="amount-bet-color amount-style"
                    style="cursor: pointer"
                    @click="_goToReportDetail(item)"
                  >
                    {{ currencyFormat(item.amount) }}
                  </td>
                  <td :class="item.amount_win < 0 ? 'lose-color' : 'win-color'">
                    {{ currencyFormat(item.amount_win) }}
                  </td>
                  <td>
                    <v-btn color="primary" @click="_openConfigUser(item)">
                      <v-icon>mdi-account-star</v-icon></v-btn
                    >
                  </td>
                </tr>
              </template>
              <template slot="body.append">
                <tr>
                  <td :colspan="5" class="text-xs-center pl-4 py-2">
                    {{ $t("title.total") }}
                  </td>
                  <td></td>
                  <td class="text-xs-center total-style pl-4 py-2">
                    {{
                      getCountStatement.amount
                        ? currencyFormat(getCountStatement.amount)
                        : 0
                    }}
                  </td>
                  <td
                    class="
                      text-xs-center
                      pl-4
                      total-style
                      font-weight-bold
                      py-2
                    "
                    :style="
                      getCountStatement.amount_win < 0
                        ? 'color:#BA2D4A'
                        : 'color:#39afd6'
                    "
                  >
                    {{
                      getCountStatement.amount_win
                        ? currencyFormat(getCountStatement.amount_win)
                        : 0
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
            <loader
              v-if="getLoading == true"
              object="#ff9633"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              name="circular"
            ></loader>
            <statement-list5
              :darkmode="darkmode"
              :dialogDetailReport="dialogDetailReport"
              :passListDetailReport="listDetailReport"
              :data-request="dataDetail"
              v-on:updatePage="_goToReportDetail(item)"
              :user="user"
              :passSelectedUser="selectedUser"
              @onSearch="onSearch"
              @onCloseDialog="dialogDetailReport = !dialogDetailReport"
              :date="date"
            />
            <DialogConfigUser
              :darkmode="darkmode"
              :dialogConfig="dialogConfig"
              :dataConfig="configUserData"
              :loading="loading"
              :passLimitConfig="getParentLimitData"
              v-on:submit="onChangeConfigUser"
              :passPlayer="selectedPlayer"
              v-on:onCloseDialog="closeConfigUserDialog"
              v-on:changeTab="onChangeTab"
            />
            <!-- <div class="text-center pt-2">
              <v-pagination
                v-model="data.page"
                :length="
                  getCountStatement
                    ? getCountStatement.count % data.rowsPerPage != 0
                      ? getCountStatement.count / data.rowsPerPage + 1
                      : getCountStatement.count / data.rowsPerPage
                    : 0
                "
              ></v-pagination>
              <v-text-field
                :value="data.rowsPerPage"
                label="Items per page"
                type="number"
                min="-1"
                max="15"
                @input="data.rowsPerPage = parseInt($event, 10)"
              ></v-text-field>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import moment from "moment";

export default {
  props: ["date"],
  components: {
    Header: () => import("./_components/HeaderTable.vue"),
    StatementList5: () => import("./_components/statementList/Dialog5"),
    DialogConfigUser: () => import("./_components/ConfigUserData"),
  },

  data: () => ({
    valid: true,
    headers: headers,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["_id", "user_name", "balance", "amount", "amount_win", "app_id"],
      search: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      channelType: 1,
      currencyType: 2,
      price: 0,
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    listDetailReport: [],
    check: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    dataDetail: {
      descendingFormat: [true],
      descending: true,
      sortByFormat: ["_id"],
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "co_type_of_betting.name",
        "co_type_of_betting.desc",
        "co_type_of_betting.color",
        "date",
        "fight_no",
        "is_win",
        "amount",
        "amount_win",
        "cast",
      ],
      search: "",
      channelType: 0,
      groupNo: 0,
    },
    totalItems: 0,
    time: null,
    dialogDetailReport: false,
    selectedUser: [],
    item: [],
    // configuser
    dialogConfig: false,
    player: [],
    ipDialog: false,
    onCloseDialog: false,
    passLimitConfig: {},
    selectedPlayer: {},
    configUserData: {},
    channel_type: 1,
  }),
  mounted() {
    this.$cookies.set("currency-type", "1");
    this.$cookies.set("channel-type", "1");
  },
  watch: {
    user: function () {
      this.getData();
    },
    data: function () {
      this.getData();
    },
    getStatement: function () {},
  },
  computed: {
    reportUrl: function () {
      return "getViewTopRangeWinnerApiBranch";
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_statementTopRangeWinnerReports", [
      "getStatement",
      "getCountStatement",
      "getLoading",
      "getParentLimitData",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    this.$cookies.set("currency-type", "1");
    this.$cookies.set("channel-type", "1");
  },
  methods: {
    moment,
    selectDate({ name }) {
      const { format } = this;
      switch (name) {
        case "Today":
          this.startDate = this.moment().format(format);
          this.endDate = this.moment().format(format);
          break;
        case "Yesterday":
          this.startDate = this.moment().subtract(1, "days").format(format);
          this.endDate = this.moment().subtract(1, "days").format(format);
          break;
        case "This Week":
          this.startDate = this.moment().startOf("isoweek").format(format);
          this.endDate = this.moment().endOf("isoWeek").format(format);
          break;
        case "Last Week":
          this.startDate = this.moment()
            .subtract(1, "isoweek")
            .startOf("isoweek")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "isoweek")
            .endOf("isoweek")
            .format(format);
          break;
        case "This Month":
          this.startDate = this.moment().format("YYYY-MM-01");
          this.endDate = this.moment().format(format);
          break;
        case "Last Month":
          this.startDate = this.moment()
            .subtract(1, "months")
            .startOf("month")
            .format(format);
          this.endDate = this.moment()
            .subtract(1, "months")
            .endOf("month")
            .format(format);
          break;
        default:
          break;
      }
      const { startDate, endDate } = this;
      this.time = { startDate: startDate, endDate: endDate };
      this.data.startDate = startDate;
      this.data.endDate = endDate;
      this.getData();
      //console.log("this.getData():",this.getData());
      this.check = { startDate, endDate };
    },
    // Gets the checkbox information from the child component
    checkboxValue: function (params) {
      this.check = params;
      this.data.startDate = params.startDate;
      this.data.endDate = params.endDate;
      (this.data.price = params.price ? parseInt(params.price) : 0),
        (this.data.search = params.search ? params.search : "");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    async getData() {
      try {
        this.data.channelType = parseInt(this.$cookies.get("channel-type"));
        this.data.currencyType = parseInt(this.$cookies.get("currency-type"));
        this.loader = true;
        let preData = {
          data: this.data,
          user_id: this.user._id,
          reportUrl: this.reportUrl,
        };
        // console.log('pre_data:',preData);
        this.checkUserPermission("VIEW")
          ? await this.fetchListStatement(preData)
          : [];
        // console.log(this.getCountStatement)
        this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    _goToReportDetail(item, search = "", channelType = 0) {
      let linkURL;
      if (parseInt(this.$cookies.get("currency-type")) == 1)
        linkURL = "getViewTopWinPlayerDetailSummaryUSD";
      if (parseInt(this.$cookies.get("currency-type")) == 2)
        linkURL = "getViewTopWinPlayerDetailSummaryKH";
      if (search) this.dataDetail.search = search;
      else this.dataDetail.search = "";
      this.selectedUser = item._id;
      // console.log(this.selectedUser);
      this.item = item;
      this.dataDetail.sortByFormat.length < 1
        ? (this.dataDetail.sortBy = "_id")
        : (this.dataDetail.sortBy = this.dataDetail.sortByFormat[0]);
      this.dataDetail.descendingFormat.length > 0
        ? (this.dataDetail.descending = this.dataDetail.descendingFormat[0])
        : (this.dataDetail.descending = true);
      this.dataDetail.groupNo = item.group_no;
      this.dataDetail.channelType = channelType;
      this.$request
        .post({
          url: "adminReport/" + linkURL + "/" + item._id,
          data: this.dataDetail,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogDetailReport = true;
            this.listDetailReport = res.data.data;
          }
        });
    },
    onSearch(search, channelType) {
      this._goToReportDetail(this.item, search, channelType);
    },
    async _openConfigUser(player) {
      this.selectedPlayer = player;
      await this.upConfigUser(player);
      this.dialogConfig = true;
    },
    async onChangeConfigUser(payload) {
      this.loading = true;
      let dataLimit = this.configUserData.filter(channel => channel.channel_type == payload.channel_type);
      let data;

      if (dataLimit.length > 0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
          is_controller: payload.is_controller
        }
      }else{
        data = this.configUserData;
      }
      if (data._id) {
        await this.updateConfigUser({ vm: this, d: data });
        this.loading = false;
        // this.dialogConfigUserData = false;
      }
    },
    async upConfigUser(player) {
      let data = { currency_type: player.currency_type, _id: player._id };
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
        });
        this.channel_type = this.configUserData[0] ?this.configUserData[0].channel_type : 1;
      await this.getLimitDataFromApi(player, this.channel_type);
    },

    async getLimitDataFromApi(player, channel_type) {
      let submitLimitData = {
        currency_type: player.currency_type,
        _id: player.parent_id,
        channel_type: channel_type
      };
      await this.fetchLimitData(submitLimitData);
    },
    async onChangeTab(channel_type){
      let submitLimitData = {
        currency_type: this.selectedPlayer.currency_type,
        _id: this.selectedPlayer.parent_id,
        channel_type: channel_type
      };
      await this.fetchLimitData(submitLimitData);
    },
    closeConfigUserDialog() {
      this.dialogConfig = false;
    },
    ...mapMutations("$_statementTopRangeWinnerReports", [
      "LIST_STATEMENT",
      "COUNT-STATEMENT",
      "LIST_CHANNEL_TYPE",
      "PARENT_LIMIT_DATA",
      "UPDATE_POPUP",
    ]),
    ...mapActions("$_statementTopRangeWinnerReports", [
      "fetchListStatement",
      "fetchChannelType",
      "fetchLimitData",
      "updateConfigUser",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}
.amount-style {
  text-decoration: underline !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
