/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchListStatement = async({ commit }, data) => {

    const response = await request.post({
        url: 'outstanding/cock/0',
        data:data
    });
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
    }

};
const updateListStatement = async({ commit }, data) => {
    if (data) {
        commit('LIST_STATEMENT', data);
    }
};

const fetchGetCurrentChannel = async({ commit }) => {
    commit('LOADING', response.data);
    const response = await request.get({
        url: 'coChannel/getCurrentChannel',
    });
    if (response.data.code) {
        commit('LIST_CHANNEL', response.data);
    }
};
const updateChannelUrl = async({ commit },  {vm, data}) => {
    const response = await request.post({
        url: 'coChannel/updateChannelUrl',
        data: data
    });
    if (response.data.code) {
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else vm.$toastr.e(`${response.data.message.descriptions}`)
};



export default {
    fetchListStatement,
    fetchGetCurrentChannel,
    updateChannelUrl,
    updateListStatement
};