const UPDATE_BANK_USER = (state, data) => {
	state.bankUser = data;
};

const UPDATE_POPUP = (state, data) => {
	if (!data) {
		state.currentBankUser = {
			user_id: null,
			bank_id: null,
			account_name: null,
			account_number: null,
			account_info: null,
			is_active: true,
			is_enable: true,
			currency_type: null,
			order: null,
			}
	}
	state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
	state.deleteItems = data
}

const UPDATE_CURRENT_BANK_USER = (state, data) => {
	state.currentBankUser = data;
};

const UPDATE_PARENT_BANK_USER = (state, data) => {
	state.bankUserParent = data;
};

const UPDATE_LOADING = (state, data) => {
	state.loading = data
}

export default {
	UPDATE_BANK_USER,
	UPDATE_POPUP,
	DELETE_ITEMS,
	UPDATE_CURRENT_BANK_USER,
	UPDATE_PARENT_BANK_USER,
	UPDATE_LOADING
};
