<template>
  <div>
    <div class="section-header"></div>
    <div class="section-body">
      <v-data-table
        style="font-family: 'khmer mef1'"
        class="elevation-1 table"
        item-key="_id"
        :headers="headers"
        :items="getSetting && getSetting.object ? getSetting.object : []"
        :footer-props="footerProps"
        :server-items-length="getSetting ? getSetting.objectCount : null"
        :page.sync="data.page"
        :items-per-page.sync="data.rowsPerPage"
        :sort-desc.sync="data.descendingFormat"
        :sort-by.sync="data.sortByFormat"
        @update:page="getData"
        @update:items-per-page="getData"
        @update:sort-desc="getData"
        @input="itemDelete"
        :loading="getLoading"
        show-select
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.is_enable`]="{ item }">
          <v-icon v-if="item.is_enable == true" small
            >mdi-checkbox-marked-circle-outline
          </v-icon>
          <v-icon v-else-if="item.is_enable === false" small
            >mdi-checkbox-blank-circle-outline
          </v-icon>
        </template>
        <!-- <template v-slot:top>
          <Header
            :darkmode="darkmode"
            :params="data"
            :dataDelete="getDeleteItems"
            :user-permission="userAccessPermission"
          />
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <ActionComponents
            :darkmode="darkmode"
            :item="item"
            :params="data"
            :user-permission="userAccessPermission"
          />
        </template>
      </v-data-table>
      <Dialog
        :darkmode="darkmode"
        :loading="loading"
        :setting="getCurrentSetting"
        :dialog="getPopUp"
        :is-validate.sync="valid"
        v-on:submit="handleSubmit"
        v-on:onCloseDialog="fetchPopUp(false)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    ActionComponents: () => import("./_components/action"),
    // Header: () => import("./_components/header.vue"),
    Dialog: () => import("./_components/dialog.vue"),
  },
  data: () => ({
    valid: true,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 10,
      fields: ["name", "ip_remote_allow", "channel_type"],
      search: "",
    },
    loading: false,
    app_id:0,
  }),
  computed: {
    headers() {
      return [
        { text: "Name", align: "start", value: "name" },
        {
          text: this.$t("header.channel_type"),
          value: "channel_type",
          sortable: false,
        },
        {
          text: "Ip Remote Allow",
          value: "ip_remote_allow",
          sortable: false,
        },
        {
          text: "Is Remote Allow",
          value: "is_remote_allow",
          sortable: false,
        },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_resultSetting", [
      "getSetting",
      "getPopUp",
      "getCurrentSetting",
      "getDeleteItems",
      "getLoading",
    ]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
  },
  methods: {
    async handleSubmit(payload) {
      this.loading = true;
      this.getCurrentSetting.selection = payload;
      console.log("payload",payload);
      if (!this.getCurrentSetting._id) {
        await this.createSetting({ vm: this, data: this.getCurrentSetting });
      } else {
        this.getCurrentSetting.channel_type = parseInt(this.getCurrentSetting.channel_type);
        console.log(this.getCurrentSetting);
        await this.updateSetting({ vm: this, data: this.getCurrentSetting });
      }
      await this.getData();
      this.loading = false;
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListSetting(this.data)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },
    ...mapMutations("$_resultSetting", ["DELETE_ITEMS", "UPDATE_SETTING"]),
    ...mapActions("$_resultSetting", [
      "fetchListSetting",
      "fetchPopUp",
      "createSetting",
      "updateSetting",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_SETTING([]);
    next();
  },
};
</script>
