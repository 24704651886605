const getConfigUserData = (state) => state.configUserData;
const getConfigSlotLimitData = (state) => state.configSlotLimitData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getUsers = (state) => state.users;

const getConfigUserbyId = (state) => state.users;

const getCurrentConfigUserData = (state) => state.currentConfigUserData;

const getUserConfigData = (state) => state.userConfigData;

export default {
    getConfigUserData,
    getLoading,
    getDeleteItems,
    getPopUp,
    getUsers,
    getCurrentConfigUserData,
    getConfigUserbyId,
    getUserConfigData,
    getConfigSlotLimitData
};