<template>
  <div>
    <v-row>
      <v-layout class="table-result" style="width:100%!important">
      <table class="d-flex flex-row justify-content-center" >
        <tbody style="background-color: #fff; border-radius: 5px;">
          
          <tr v-if="orderResult.length > 0">
            <td  style="height:60px !important; width:60px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                 #
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P1</span>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P2</span>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; text-align: center;">
                <span style="font-weight: bold; color: #000000; font-size: 30px;  text-align: center;vertical-align: middle;line-height: 60px; ">P3</span>
              </div>  
            </td>  
            <td v-for="(result, i) in orderResult" :key="i" style="height:60px !important; width:60px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  <span v-if="result.fight_no" height="50px"> {{result.fight_no }} </span>
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;">
                <v-avatar
                    :color="switchResult(result.result1_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result1_name) }}</span>
                </v-avatar>
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;">
                <v-avatar
                    :color="switchResult(result.result2_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result2_name) }}</span>
                </v-avatar>
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 60px; padding-top: 6px; text-align: center;">
                <v-avatar
                    :color="switchResult(result.result3_name)"
                    size="45"
                >
                <span style="font-weight: bold; color: #fff;">{{ switchName(result.result3_name) }}</span>
                </v-avatar>
              </div>  
            </td>                                 
        </tr>
        <tr v-else>
          <td  style="height:52px !important; width:52px !important">
              <div style="border:1px solid #000000; text-align: center;padding:5px; background-color: #39688e;color:#fff">
                  &nbsp;
              </div>
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>                         
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div> 
              <div style="border:1px solid #000000; width: 60px; height: 50px; text-align: center;">
                  
              </div>  
            </td>  
        </tr>
        </tbody>
      </table>
    </v-layout>
    </v-row>

    <v-layout class="py-5" justify-center>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 1'] }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 2']  }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="red"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Banker 3']  }}
        </span>
      </v-avatar>

      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 1'] }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 2'] }}
        </span>
      </v-avatar>
      <v-avatar
          class="mx-5"
          color="blue"
          size="56"
      >
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['Player 3'] }}
        </span>
      </v-avatar>
      <v-avatar class="mx-5" color="grey" size="56">
        <span class="white--text text-h5 font-weight-bold">
          {{ objSummaryCoResult['CANCEL'] }}
        </span>
      </v-avatar>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ['objCoResult', 'channel', 'objSummaryCoResult' , 'objCoTypeOfBetting'],
  data() {
    return {
      defineResult: {
        chanel_id: null,
        resultTypeId: null
      },
      disableBlue : false,
      disableRed : false,
      break : false,
      allowToClick : true,
      loadingData : false
    }
  },
  watch: {
    objCoResult: function () {
    },
    objCoTypeOfBetting:function(){
    },
    channel: function () {
    },
    objSummaryCoResult: function (){
    },
    allowToClick: function(){},
    loadingResult: function(){
    }
  },
  mounted(){
    
  },
  computed: {
    orderResult() {
      return this.getHan2Results();
    },

...mapGetters("$_han2Ch20Channel", {
  loadingResult: "getLoadingResult",
}),
},
  methods: {
    getHan2Results() {
        let row = 1;
        let col = 1;
        let newResult = [];
        if(this.objCoResult !== null){
          let resultList = this.objCoResult;
         
          for (var index = resultList.length - 1; index >= 0; index--) {
            const item = resultList[index];
            if(row <= this.rows){
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }else{
              row = 1
              col += 1
              newResult.push({
                ...item,
                row,
                col,
              });
              row += 1;
            }
          }
        }
        return newResult;
    },
    switchResult(name) {
      let data;
      switch (name) {
        case 'Banker 1':
          data = "#b31013";
          return data;
        case 'Banker 2':
          data = "#b31013";
          return data;
        case 'Banker 3':
          data ="#b31013";
          return data;
        case 'Player 1':
          data = "#0b4592";
          return data;
        case 'Player 2':
          data = "#0b4592";
          return data;
        case 'Player 3':
          data = "#0b4592";
          return data;
        case 'CANCEL':
          data = "#9e9e9e";
          return data;
      }
    },
    switchName(name) {
      let data;
      switch (name) {
        case 'Banker 1':
          data = "B1";
          return data;
        case 'Banker 2':
          data = "B2";
          return data;
        case 'Banker 3':
          data ="B3";
          return data;
        case 'Player 1':
          data = "P1";
          return data;
        case 'Player 2':
          data = "P2";
          return data;
        case 'Player 3':
          data = "P3";
          return data;
        case 'CANCEL':
          data = "C";
          return data;
      }
    },
    
  }
}
</script>

<style scoped>
/* .table-result {
 background-color: #acb8bd;
  overflow-x: scroll;
  width: 100%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
} */
.table-result {
  background-color: #acb8bd;
  overflow-x: scroll;
  width: 50%;
  margin-top: 10px;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.td-table-style {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: solid 1px #ccc;
  text-align: center;
}

.cir {
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 57px;
  width: 57px;
  max-width: 67px;
  max-height: 67px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}

.cir-blue {
  border: 25px solid #0b4592;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-red {
  border: 25px solid #b31013;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-green {
  border: 25px solid #28a745;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.cir-cancel {
  border: 25px solid grey;
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 35px;
  width: 35px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 25px;
  margin: 3px 3px 2px 2px;
}

.no-circle {
  width: 53px;
  height: 55px;
  display: block;
}
.cancel-btn {
  color: white !important;;
  background-color:grey!important;
}
.agree-btn {
  color: white !important;;
  background-color:#3F9542!important;
}
.confirm-circle{
  width:120px; 
  height:120px;
  border-radius:100px;
  padding:20px;
  margin-top:20px;
}
.meron{
  background-color:#b31013
}
.wala{
  background-color:#0b4592
}
.tie{
  background-color:#3F9542
}
.cancel{
  background-color:gray
}
.meron-text{
  color:#b31013
}
.wala-text{
  color:#0b4592
}
.tie-text{
  color:#3F9542
}
.cancel-text{
 color:gray
}
.fight-no-style{
  margin-left: -17.5px;
  width: 35px;
  margin-top: -11px;
  font-size: 16px;
  color:white
}
td:hover{
  background-color: #ffbf00 !important;;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #cef;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
