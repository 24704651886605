import reverseBaccaratCh18 from '@/modules/reverseResult/baccaratCh18';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseBaccaratCh18',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseBaccaratCh18,
        name: 'reverseBaccaratCh18'
    }]
}];

export default routes;