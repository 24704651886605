<template>
  <div>
    <!-- sort-by="user_name" -->
    <v-data-table
      :headers="headers"
      :items="getStatement ? getStatement.object : []"
      :server-items-length="getCountStatement? parseInt(getCountStatement.count) : null"
      item-key="_id._id"
      class="elevation-1"
      style="font-family: 'khmer mef1'"
      :footer-props="footerProps"
      :page.sync="data.page"
      :items-per-page.sync="data.rowsPerPage"
      :sort-desc.sync="data.descendingFormat"
      :sort-by.sync="data.sortByFormat"
      @update:page="getData"
      @update:items-per-page="getData"
      @update:sort-desc="getData"
      :loading="getLoading"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <Header
          :darkmode="darkmode"
          :params="data"
          :user="user"
          :time="time"
        />
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr>
          <td>{{ (getStatement.object.indexOf(item)  + ( (data.page -1 ) *  data.rowsPerPage))+ 1 }}</td>
          <td>{{ item._id.app_id }}</td>
          <td>{{ item._id.com }}</td>
          <td>{{ item._id.admin }}</td>
          <td>{{ item._id.master }}</td>
          <td>{{ item._id.agent }}</td>
          <td>{{ item._id.player }}</td>
          <!-- <td>{{ item._id.public_ip }}</td> -->
          <!-- <td>{{ currencyFormat(item._id.player_balance) }}</td> -->
          <td class="amount-bet-color to-detail" @click="_goToDetail(item._id.player_id)">{{ currencyFormat(item.amount) }}</td>
          <td :class="item.amount_win  < 0 ? 'lose-color' : 'win-color'">{{ currencyFormat(item.amount_win ) }}</td>
        </tr>
      </template>
      <template slot="body.append">
        <tr>
          <td :colspan="7" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total") }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getCountStatement.amount
                ? currencyFormat(getCountStatement.amount)
                : 0
            }}
          </td>
          <td
            class="text-xs-center pl-4 total-style font-weight-bold  py-2"
            :style="
              getCountStatement.amount_win < 0
                ? 'color:#BA2D4A'
                : 'color:#39afd6'
            "
          >
            {{
              getCountStatement.amount_win 
                ? currencyFormat(
                    getCountStatement.amount_win
                  )
                : 0
            }}
          </td>
        </tr>
      </template>
    </v-data-table>
      <v-dialog
        :value="dialog"
        max-width="70%"
        persistent
        transition="dialog-bottom-transition"
        style="z-index: 9999 !important"
    >
      <v-card >
        <v-toolbar>
          <v-btn icon  @click="dialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
              <v-toolbar-title>Detail Player Statement</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                <v-select
                  v-model="defaultSelected"
                  :items="selectChannel"
                  item-text="name"
                  item-value="id"
                  style="margin-top:-10px"
                  solo
                ></v-select>
            </v-col>
              <v-col cols="8" sm="8" md="4" class="text-right">
                <v-text-field
                    v-model="v_search"
                    append-icon="mdi-magnify"
                    label="Search ( Fight no, គូ ប្រកួត )"
                    single-line
                    hide-details
                    clearable
                    class="mr-5"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" sm="4" md="2" class="text-right mb-5">
                <v-btn
                    class="white--text btn-custom mr-4 mt-3"
                    color="green darken-1"
                    depressed
                    @click="searchq"
                >
                  <!-- @click="fetchListStatement(params)" -->
                  SEARCH
                </v-btn>
              </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="date"
              :headers="headersDetail"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetail"
              mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                <td>{{ item.app_id }}</td>
                <!-- <td>{{ item.public_ip }}</td> -->
                <td>{{ item.date }}</td>
                <td>{{ item.fight_no }}</td>
                 <!-- <td>{{ item.channel_type }}</td> -->
                <td>{{ item.bet_name }}</td>
                <!-- <td :class="item.co_result.desc">
                  {{ item.co_result.desc }}
                </td> -->
                <td class="amount-bet-color">
                  <!-- {{ dataRequest && dataRequest.currencyType == 2 ? currencyFormatKm(item.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 ?  currencyFormatEn(item.amount) :"Loading ..." }} -->
                   {{currencyFormatEn(item.amount)}}
                </td>
                <td  >{{ currencyFormatEn(item.payout) }}</td>
                <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{ item.amount_win != null ? currencyFormatEn(item.amount_win) : 0  }}</td>
                <td>{{ item.cast != null ? currencyFormatEn(item.cast) : 0  }}</td>
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="5" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td :class=" detailDataCount.amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
               
                {{ detailDataCount ? currencyFormatEn(detailDataCount.amount) : "loading ..."}}  
              </td>
              <td></td>
              <td :class=" detailDataCount.amount_win  < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                <!-- 123 -->
                <!-- {{ dataRequest && dataRequest.currencyType == 2 && detailDataCount ? currencyFormatKm(detailDataCount.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 && detailDataCount ?  currencyFormatEn(detailDataCount.amount) :"Loading ..." }} -->
                {{ detailDataCount ? currencyFormatEn(detailDataCount.amount_win) : "loading ..."}}  
              </td>
              
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headers from "./_api/columns";
import headersDetail from "./_api/columns_detail";
import moment from "moment";

export default {
  components: {
    // ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
  },

  data: () => ({
    defaultSelected: {
      id : 0,
      name : "បុស្តិ៍ទាំងអស់",
    },
    selectChannel:[
      {
      id : 0,
      name : "បុស្តិ៍ទាំងអស់",
      },
      {
      id : 1,
      name : "CO1",
      },
      {
      id : 2,
      name : "CO2",
      },
      {
      id : 3,
      name : "CO3",
      },
      {
      id : 4,
       name : "CO4",
      },
      {
      id : 5,
       name : "KL1",
      },
      {
      id : 6,
       name : "BA1",
      },
      {
      id : 7,
       name : "BA2",
      },
      {
      id : 8,
       name : "TD1",
      },
      {
      id : 9,
       name : "TD2",
      },
      {
      id : 10,
       name : "LO1",
      },
      {
      id : 11,
       name : "YU1",
      },
      {
      id : 12,
       name : "AP1",
      },
    ],
    v_search:null,
    valid: true,
    headers: headers,
    headersDetail:headersDetail,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
     dataRequest:{
      descendingFormat: [false],
      sortByFormat: ["amount_win"],
      descending: false,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "app_id",
        "public_ip",
        "type_of_betting.name",
        "date",
        "fight_no",
        "is_win",
        "amount",
        "amount_win"
      ],
      search: "",
      channelType:  0,
      currencyType: 2
    },
    data: {
      descendingFormat: [false],
      sortByFormat: ["amount_win"],
      descending: false,
      sortBy: "amount_win",
      page: 1,
      rowsPerPage: 10,
      fields: [
        "_id.com",
        "_id.admin",
        "_id.master",
        "_id.agent",
        "_id.player",
        "_id.app_id",
        "app_id",
        "amount",
        "amount_win"
      ],
      search: "",
      currencyType: 2,
      channelType: 0
    },
    dialog: false,
    loading: false,
    loader: false,
    user_id: null,
    detailData:[],
    detailDataCount:[],
      check: {
      startDate: moment().format("YYYY-MM-DD")+"T00:00:00",
      endDate:  moment().format("YYYY-MM-DD")+"T23:59:59",
    },
    format: "YYYY-MM-DD",
    defaultFilterDates: [
      {
        name: "Today",
        active: false,
      },
      {
        name: "Yesterday",
        active: false,
      },
      {
        name: "This Week",
        active: false,
      },
      {
        name: "Last Week",
        active: false,
      },
      {
        name: "This Month",
        active: false,
      },
      {
        name: "Last Month",
        active: false,
      },
    ],
    totalItems: 0,
    time: null,
    getLoadingDetail:false,
    user_iid : null,
    channel_type:null
  }),
  watch: {
    user: function() {
      this.getData();
    },
    data: function() {
      this.getData();
    },
    getStatement: function() {},
  },
  computed: {
    reportUrl: function() {
      if (this.user) {
        if (this.user.role_name == "CONTROLLER" || this.user.role_name == "MANAGER" ||  this.user.role_name == "SPECIAL-MANAGER" ) return "getViewPlayerByGroup";
        else return null;
      } else return null;
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", user: "getUser" }),
    ...mapGetters("$_ocControlStatementSummaryPlayerUSD", ["getStatement", "getCountStatement","getLoading",]),
  },
  async created() {
    await this.getData();
  },
  methods: {
    moment,
     _goToDetail(_id){
       this.user_iid = _id;
      this.loadDetailData(_id);
      this.dialog = true;
    },
    searchq() {
      if( this.defaultSelected && this.defaultSelected._id) this.channel_type = this.defaultSelected._id;
      else this.channel_type = this.defaultSelected;
      this.dataRequest.search = this.v_search;
      this.loadDetailData(this.user_iid)
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.user_iid);  
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id){
      this.detailData = [],
      this.detailDataCount = [],
      this.getLoadingDetail = true;
      if( this.channel_type != null &&  typeof this.channel_type === 'object' ) {
        this.dataRequest.channelType = this.channel_type.id;
      }
      if( this.channel_type != null &&  typeof this.channel_type !== 'object' ) {
        this.dataRequest.channelType = this.channel_type;
      }
      console.log("this.channel_type",this.channel_type);
      // this.dataRequest.startDate = this.data.startDate;
      // this.dataRequest.endDate = this.data.endDate;
      this.dataRequest.currencyType = this.data.currencyType;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
     await this.$request
        .post({
          url: "adminReport/getViewPlayerDetailByGroup/"+_id,
          data: this.dataRequest,
        })
        .then((res) => {
          if (res.data.code) {
            
            this.detailData = res.data.data.object;
            this.detailDataCount = res.data.data.objectCount;
            this.getLoadingDetail = false;
          }else{
            
            this.getLoadingDetail = false;
          }
        });
    },

    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
     currencyFormatKm(num) {
      num = parseFloat(num);
      return num.toLocaleString("en");
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString('en')
    },
    async getData() {
      try {
        this.loader = true;
          let preData = {
            data: this.data,
            reportUrl: this.reportUrl,
          };
          await this.fetchListStatement(preData)
          this.loader = false;
      } catch (error) {
        // console.log("")
      }
    },
    ...mapMutations("$_ocControlStatementSummaryPlayerUSD", ["LIST_STATEMENT", "COUNT-STATEMENT"]),
    ...mapActions("$_ocControlStatementSummaryPlayerUSD", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>

.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.amount-bet-color {
  color: goldenrod !important;
}
.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}
.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.to-detail{
  cursor: pointer;
  text-decoration: underline;
}
.MERON{
  color: #ba2d4a;
  font-weight: bold;
}
.WALA{
   color: rgb(57, 175, 214);
  font-weight: bold;
}
</style>
