import LottoSetting from '@/modules/slotMachineSetting/lottoSetting';
import Layout from '@/layouts/default';

const routes = [
	{
		path: '/lotto-setting',
		component: Layout,
		meta: {
			permission: 'master',
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'LottoSetting',
				component: LottoSetting
			}
		]
	}
];

export default routes;
