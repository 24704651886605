<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="channel.fight_no" label="Fight Number" readonly class="right-input" />
          </v-col>
          <v-col cols="4">
            <template>
              <v-select :items="[false, true]" v-model="channel.is_maintain" color="warning"
                label="Maintanance status" />
            </template>
          </v-col>
          <v-col cols="4">
            <template>
              <v-select :items="[false, true]" v-model="channel.is_stop_video" color="warning" label="Video Status" />
            </template>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="channel.maintain_desc" label="Maintanance Description" :rules="rules" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="channel.info" label="Channel Information" :rules="rules" />
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="channel.video_info" label="Video Information" :rules="rules" />
          </v-col>
          <v-col cols="2" class="w-100 justify-content-center" style="text-align:center; padding-top: 25px;">
            <v-btn @click="increaseColumn" color="blue-grey" style="width:70%;">
              Column +
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="text-left update-chanel">
            <v-btn color="error" @click="alertResetResultDialog" :disabled="
              channel.is_open == false ||
                loading == true ||
                channel.is_open == true
                ? true
                : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-left text-h5">
            <v-btn color="success" @click="alertFormResultDialog" :disabled="
              channel.is_open == true ||
                loading == true ||
                channel.is_open == null 
                ? true
                : false
            "
            >
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Set Result
            </v-btn>
          </v-col>
          <v-col cols="3" class="text-center text-h5 channel-status-col">
            <span :class="[
              channelStatus(channel.is_open).color,
              'uppercase pl-4 channel-status',
            ]">
              {{ channelStatus(channel.is_open).status }}</span>
          </v-col>
          <v-col cols="4" class="text-right">
            <span class="uppercase update-channel-status mr-5">
              {{ channelUpdateStatus(channel.is_open).desc }}
            </span>
            <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
              @click="_updateChanelStatus" :disabled="channel.is_open == false || !canUpdateStatus">
              {{ channelUpdateStatus(channel.is_open).status }}
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right update-chanel">
            <v-btn color="primary" @click="update" :disabled="
              channel.is_open == false || loading == true ? true : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  dialog Form result  -->
    <v-dialog v-model="dialogFormResult" width="400" height="600" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Form Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-row>
            <v-col cols="4" style="margin-top: 40px;font-weight: bold; color: #000000; font-size: 30px;">
              <span><h1>P1</h1></span>
            </v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <v-btn class="py-10 px-5 btn-height" @click="selectResult1">
                    <div v-if="result_id_1 == '615d4a899e9ab44fe23eb9db'" class="cir-red"></div>
                    <div v-else-if="result_id_1 == '615d4aa59e9ab44fe23eb9dd'" class="cir-blue"></div>
                    <div v-else-if="result_id_1 == '615d5a609e9ab44fe23eba42'" class="cir-green"></div>
                    <div v-else-if="result_id_1 == '615d5a759e9ab44fe23eba43'" class="cir-gray"></div>
                    <div v-else class="cir-shadow"></div>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" style="margin-top: 40px;font-weight: bold; color: #000000; font-size: 30px;">
              <span><h1>P2</h1></span>
            </v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <v-btn class="py-10 px-5 btn-height" @click="selectResult2">
                    <div v-if="result_id_2 == '648fb0075284f65204c379a3'" class="cir-red"></div>
                    <div v-else-if="result_id_2 == '648fb01c5284f65204c379a4'" class="cir-blue"></div>
                    <div v-else-if="result_id_2 == '648fb0475284f65204c379a5'" class="cir-green"></div>
                    <div v-else-if="result_id_2 == '6491183b222ff55fcc00fcdb'" class="cir-gray"></div>
                    <div v-else class="cir-shadow"></div>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row>
            <v-col cols="4" style="margin-top: 40px;font-weight: bold; color: #000000; font-size: 30px;">
              <span><h1>P3</h1></span>
            </v-col>
              <v-col cols="4">
                <v-btn-toggle>
                  <v-btn class="py-10 px-5 btn-height" @click="selectResult3">
                    <div v-if="result_id_3 == '648fb0655284f65204c379a6'" class="cir-red"></div>
                    <div v-else-if="result_id_3 == '648fb0805284f65204c379a7'" class="cir-blue"></div>
                    <div v-else-if="result_id_3 == '648fb0b95284f65204c379a8'" class="cir-green"></div>
                    <div v-else-if="result_id_3 == '64911845222ff55fcc00fcdc'" class="cir-gray"></div>
                    <div v-else class="cir-shadow"></div>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="cancelResult">Cancel</v-btn>
          <v-btn color="primary" @click="saveResult" :disabled="loadingResult">Save</v-btn>
          <td style="text-align:center " v-if="loadingResult">
            <div class="lds-roller my-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </td> 
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSelectResult1" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_1">
            <btn-result :value="'615d4a899e9ab44fe23eb9db'" :color="'cir-red'" />
            <btn-result :value="'615d4aa59e9ab44fe23eb9dd'" :color="'cir-blue'" />
            <btn-result :value="'615d5a609e9ab44fe23eba42'" :color="'cir-green'" />
            <btn-result :value="'615d5a759e9ab44fe23eba43'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm1">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog result  -->
    <v-dialog v-model="dialogSelectResult2" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_2">
            <btn-result :value="'648fb0075284f65204c379a3'" :color="'cir-red'" />
            <btn-result :value="'648fb01c5284f65204c379a4'" :color="'cir-blue'" />
            <btn-result :value="'648fb0475284f65204c379a5'" :color="'cir-green'" />
            <btn-result :value="'6491183b222ff55fcc00fcdb'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm2">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  dialog result  -->
    <v-dialog v-model="dialogSelectResult3" width="700" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Result
        </v-card-title>
        <v-card-text class="text-center py-8">
          <v-btn-toggle v-model="result_id_3">
            <btn-result :value="'648fb0655284f65204c379a6'" :color="'cir-red'"/>
            <btn-result :value="'648fb0805284f65204c379a7'" :color="'cir-blue'"/>
            <btn-result :value="'648fb0b95284f65204c379a8'" :color="'cir-green'" />
            <btn-result :value="'64911845222ff55fcc00fcdc'" :color="'cir-cancel'" />
          </v-btn-toggle>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="warning" @click="cancelSelectResult">Cancel</v-btn> -->
          <v-btn color="primary" @click="confirm3">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnResult from "../../../../components/shared/button/btn-result";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {BtnResult},
  props: [
    "channel",
    "objSnNumber",
    "socketChannelType",
    "socketChannelCountdown",
    "objKdPayout",
  ],

  data() {
    return {
      result_id_1:null,
      result_id_2:null,
      result_id_3:null,
      defineResult: {
        chanel_id: null,
        result_id_1: null,
        result_id_2: null,
        result_id_3: null,
      },
      valid: true,
      rules: [(value) => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      canUpdateStatus: true,
      dialogResetResult: false,
      dialogSelectResult1: false,
      dialogSelectResult2: false,
      dialogSelectResult3: false,
      dialogFormResult: false,
      // onEditFixPayout: false,
      objFixPayout: {
        meron_percentage: 0,
        wala_percentage: 0,
      },
      rule_meron_percentage: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v > 0 &&
          v <= 120 &&
          v >= 20 &&
          !!v
        )
          return true;
        return "Number has to be bigger than 20 smaller than 120"
      }],
      rule_wala_percentage: [(v) => {
        if (
          v > 0 &&
          v <= 120 &&
          v >= 20 &&
          !!v
        )
          return true;
        return "Number has to be bigger than 20 smaller than 120"
      },]
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_han3Ch21Channel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  watch: {
    socketChannelType: function () { },
    socketChannelCountdown: function () { },
    loading: function () { },
    loadingResult: function(newV){
      if(newV == false) this.cancelResult();
    }
  },
  methods: {
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    alertFormResultDialog(){
      this.dialogFormResult = true;
    },
    cancelResult(){
      this.result_id_1 = null;
      this.result_id_2 = null;
      this.result_id_3 = null;
      this.defineResult= {
        chanel_id: null,
        result_id_1: null,
        result_id_2: null,
        result_id_3: null,
      };
      this.dialogFormResult = false;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    selectResult1(){
      this.dialogSelectResult1 = true;
    },
    selectResult2(){
      this.dialogSelectResult2 = true;
    },
    selectResult3(){
      this.dialogSelectResult3 = true;
    },
    cancelSelectResult(){
      this.dialogSelectResult1 = false;
      this.dialogSelectResult2 = false;
      this.dialogSelectResult3 = false;
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
        setTimeout(this.letUpdateChanelStatus, 3000);
      }
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text",
        };
      }
    },

    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },
    increaseColumn() {
      this.channel.column = this.channel.column + 1;
      this.$emit("submitUpdateChanel", this.channel);
    },
    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult() {
      // this.confirmDialog = true;
    },

    confirm1(){
      this.dialogSelectResult1 = false;
    },
    confirm2(){
      this.dialogSelectResult2 = false;
    },
    confirm3(){
      this.dialogSelectResult3 = false;
    },
    saveResult(){
      console.log("this.loadingResult",this.loadingResult);
      if(this.result_id_1 && this.result_id_2 && this.result_id_3){
        this.defineResult.chanel_id = this.channel._id;
        this.defineResult.result_id_1 = this.result_id_1;
        this.defineResult.result_id_2 = this.result_id_2;
        this.defineResult.result_id_3 = this.result_id_3;
        this.$emit("submitResult", this.defineResult);
        if(this.loadingResult == false) {
            this.dialogFormResult = false;
            this.result_id_1 = null;
            this.result_id_2 = null;
            this.result_id_3 = null;
            this.defineResult= {
              chanel_id: null,
              result_id_1: null,
              result_id_2: null,
              result_id_3: null,
            };
          }
      }else{
        this.$toastr.e('Please select result !');
      }
    },

    async _updateChanelStatus() {
      this.$emit("submitUpdateStatus", this.resultData)
      this.canUpdateStatus = false;
      setTimeout(this.letUpdateChanelStatus, 3000);
    },

    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    // change(a) {
    //   this.onEditFixPayout = !a;
    // },
    ...mapMutations("$_channel", ["LOADING", "UPDATE_KDPAYOUT"]),
  },
};
</script>

<style scoped>
.channel-status {
  font-weight: 900;
  font-size: 55px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}
.btn-height{
  height: 110px!important;
}
.cir-shadow{
  box-shadow: 2px 2px 4px grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.cir-red {
  box-shadow: 2px 2px 4px grey;
  border: 50px solid #b31013;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.cir-blue {
  box-shadow: 2px 2px 4px grey;
  border: 50px solid #0b4592;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.cir-green {
  box-shadow: 2px 2px 4px grey;
  border: 50px solid #28a745;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
.cir-gray {
  box-shadow: 2px 2px 4px grey;
  border: 50px solid grey;
  display: block;
  color: #fff;
  text-align: center;
  background-color: transparent;
  height: 55px;
  width: 55px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 100px;
  margin: 3px 3px 2px 2px;
}
</style>
