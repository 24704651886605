import ocStatementSummaryMasterVn from '@/modules/statementReports/currency/ocStatementSummaryMasterVn';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryMasterVn',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryMasterVn,
        name: 'ocStatementSummaryMasterVn'
    }]
}];

export default routes;