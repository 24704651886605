import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    setting: {
        object: [],
        objectCount: null
    },
    popUp: false,
    currentSetting: {
        name: null,
        ip_remote_allow: "",
        is_remote_allow: false,
        channel_type: 0
    },
    deleteItems: [],
    loading: true
};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
