const UPDATE_BANNER_DATA = (state, data) => {
    state.configBannerData = data;
}

const LOADING = (state, data) => {
    state.loading = data;
}

const UPDATE_CURRENT_DATA = (state, data) => {
   state.currentBannerData = data
};

const UPDATE_POPUP = (state, data) => {
    if (!data) {
        state.currentBannerData = {
            _id: null,
            name: null,
            dob: null,
            profile_image: null,
            barcode: null,
            is_pr: false,
            is_pitbox: false,
            is_active: false,
            position: "",
            address: "",
        }
    }
    state.popUp = data;
};

const DELETE_ITEMS = (state, data) => {
    state.deleteItems = data
};
const LIST_STATEMENT = (state, data) => {
    state.statement = data;
};
const COUNT_STATEMENT = (state, data) => {
    state.countStatement = data;
};

export default {
    LIST_STATEMENT,
    COUNT_STATEMENT,
    UPDATE_BANNER_DATA,
    LOADING,
    UPDATE_POPUP,
    UPDATE_CURRENT_DATA,
    DELETE_ITEMS
};