import ocStatementSummaryCompanyVn from '@/modules/statementReports/currency/ocStatementSummaryCompanyVn';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryCompanyVn',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryCompanyVn,
        name: 'ocStatementSummaryCompanyVn'
    }]
}];

export default routes;