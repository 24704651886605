<template>
  <div>
    <div class="section-header" hidden>
      <h1>ការកំណត់ Payout Default</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a href="/">Dashboard</a></div>
        <div class="breadcrumb-item">Payout</div>
      </div>
    </div>
    <div class="section-body">
      <template class="pt-5">
        <Header
          :darkmode="darkmode"
          :params="data"
          :dataDelete="getDeleteItems"
          :user-permission="userAccessPermission"
        />
      </template>
      <v-row>
        <v-col cols="6" sm="6" md="9" class="text-left mt-4"></v-col>
        <v-col cols="6" sm="6" md="3" class="text-left mt-4 px-5">
          <div class="form-group">
            <v-select
              v-model="defaultSelected"
              :items="selectChannel"
              item-text="name"
              item-value="id"
              style="margin-top: -10px"
              @input="_selectChannel(defaultSelected)"
              single-line
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
          <v-data-table
            :headers="headers"
            :items="getPayOutData ? getPayOutData.object : []"
            :footer-props="footerProps"
            item-key="_id"
            class=" table table-striped"
            id="table-1"
            :server-items-length="getPayOutData ? getPayOutData.objectCount : null"
            style="font-family: 'khmer mef1'"
            :page.sync="data.page"
            :items-per-page.sync="data.rowsPerPage"
            :sort-desc.sync="data.descendingFormat"
            :sort-by.sync="data.sortByFormat"
            hide-default-header
            hide-default-footer
            @update:page="getData"
            @update:items-per-page="getData"
            @update:sort-desc="getData"
            :loading="getLoading"
            :value="getDeleteItems"
            @input="itemDelete"
            mobile-breakpoint="0"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head._id">
              {{ head.text }}
            </th>
          </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ActionComponents
                :darkmode="darkmode"
                :item="item"
                :dataDelete="getDeleteItems"
                :params="data"
                :user-permission="userAccessPermission"
              />
            </template>
          </v-data-table>
        </div>
          <Dialog
            :darkmode="darkmode"
            :loading="loading"
            :users="getUsers"
            :payOutData="getCurrentPayOutData"
            :dialog="getPopUp"
            :is-validate="valid"
            v-on:submit="handleSubmit"
            v-on:onCloseDialog="closeDialogCreate"
          />
            <div class="text-center pt-2">
            <v-pagination
              v-model="data.page"
              :length="
                getPayOutData
                  ? getPayOutData.objectCount % data.rowsPerPage != 0
                    ? getPayOutData.objectCount / data.rowsPerPage + 1
                    : getPayOutData.objectCount / data.rowsPerPage
                  : 0
              "
            ></v-pagination>
            <v-text-field
              :value="data.rowsPerPage"
              label="Items per page"
              type="number"
              min="-1"
              max="15"
              @input="data.rowsPerPage = parseInt($event, 10)"
            ></v-text-field>
          </div>
          <div class="loader" v-if="this.loader == true"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    ActionComponents: () => import("./_components/Action"),
    Header: () => import("./_components/HeaderTable.vue"),
    Dialog: () => import("./_components/Dialog.vue"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    data: {
      currency_type: 1,
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: [
        "target",
        "percentage_meron",
        "percentage_wala",
        "start_time",
        "end_time",
        "meron.name",
        "wala.name",
        "channelType",
      ],
      search: "",
      vip_option: 1,
      channelType: 1,
    },
    dataUser: {
      descending: true,
      sortBy: "_id",
      page: 1,
      rowsPerPage: 5000,
      fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
      search: "",
    },
    currencyType: 1,
    dialog: false,
    loading: false,
    defaultSelected: {
      id: 1,
      name: "Channel 1",
    },
    selectChannel: [
      {
        id: 1,
        name: "Channel 1",
      },
      {
        id: 2,
        name: "Channel 2",
      },
      {
        id: 3,
        name: "Channel 3",
      },
      {
        id: 4,
        name: "Channel 4",
      },
      {
        id: 15,
        name: "Channel 15",
      },
      {
        id: 16,
        name: "Channel 16",
      },
    ],
    items2: [
      { name: "ដុល្លា", id: 1 },
      { name: "រៀល", id: 2 },
      { name: "បាត", id: 3 },
    ],
  }),
  computed: {
    headers() {
      return [
        { text: this.$t("input.channel_type"), value: "channel_type" },
        { text: this.$t("input.target"), value: "target" },
        { text: this.$t("input.percentage_meron"), value: "percentage_meron" },
        { text: this.$t("input.percentage_wala"), value: "percentage_wala" },
        { text: this.$t("input.start_time"), value: "start_time" },
        { text: this.$t("input.end_time"), value: "end_time" },
        { text: this.$t("input.meron_name"), value: "meron.name" },
        { text: this.$t("input.wala_name"), value: "wala.name" },
        { text: this.$t("header.actions"), value: "actions", sortable: false },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode" }),
    ...mapGetters("$_payOut", [
      "getPayOutData",
      "getLoading",
      "getDeleteItems",
      "getPopUp",
      "getUsers",
      "getCurrentPayOutData",
    ]),
    ...mapGetters("$_role", ["getAllRolesDependOnUser"]),
  },
  async created() {
    await this.fetchUserAccessPermission(this.$route.path);
    await this.getData();
    await this.getDataUser();
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      if (!this.getCurrentPayOutData._id) {
        await this.createPayOutData({
          vm: this,
          data: this.getCurrentPayOutData,
        });
        this.getCurrentPayOutData = [];
        this.fetchPopUp(false);
      } else {
        console.log(this.getCurrentPayOutData);
        let uploadData = {
          currency_type: this.getCurrentPayOutData.currency_type,
          target: this.getCurrentPayOutData.target,
          percentage_meron: this.getCurrentPayOutData.percentage_meron,
          percentage_wala: this.getCurrentPayOutData.percentage_wala,
          meron_name: this.getCurrentPayOutData.meron.name,
          wala_name: this.getCurrentPayOutData.wala.name,
          start_time: this.getCurrentPayOutData.start_time,
          end_time: this.getCurrentPayOutData.end_time,
          meron: this.getCurrentPayOutData.meron,
          channel_type: this.getCurrentPayOutData.channel_type,
          vip_option: this.getCurrentPayOutData.vip_option,
        };
        await this.updatePayout({
          vm: this,
          data: uploadData,
          _id: this.getCurrentPayOutData._id,
        });
        this.fetchPopUp(false);
      }
      await this.getData();
      this.loading = false;
    },

    _gotoOption(selectOption) {
      this.data.vip_option = selectOption;
      this.getData();
    },

    async getData() {
      this.checkUserPermission("VIEW")
        ? await this.fetchGetAllPayOut(this.data)
        : [];
    },
    async getDataUser() {
      this.checkUserPermission("VIEW")
        ? await this.fetchListUsers(this.dataUser)
        : [];
    },

    itemDelete(e) {
      const items = e.map(function (e) {
        return { _id: e._id };
      });
      this.DELETE_ITEMS(items);
    },

    closeDialogCreate() {
      this.getCurrentPayOutData = {};
      this.fetchPopUp(false);
    },

    async _selectChannel(selectedChannel) {
      this.data.channelType = selectedChannel;
      await this.getData();
    },
    async selectCurrencyType(selectedCurrencyType) {
      this.data.currency_type = selectedCurrencyType;
      await this.getData();
    },
    ...mapMutations("$_payOut", [
      "UPDATE_PAYOUT_DATA",
      "DELETE_ITEMS",
      "UPDATE_USERS",
    ]),
    ...mapActions("$_payOut", [
      "fetchPopUp",
      "createPayOutData",
      "fetchGetAllPayOut",
      "fetchListUsers",
      "updatePayout",
    ]),
  },
  async beforeRouteLeave(to, from, next) {
    this.UPDATE_USERS([]);
    this.UPDATE_USER_ACCESS_PERMISSION([]);
    next();
  },
};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
