const getAllBanner = (state) => state.configBannerData;

const getLoading = (state) => state.loading;

const getDeleteItems = (state) => state.deleteItems;

const getPopUp = (state) => state.popUp;

const getUsers = (state) => state.users;

const getConfigUserbyId = (state) => state.users;

const getCurrenBannerData = (state) => state.currentBannerData;

const getUserConfigData = (state) => state.userConfigData;
const getStatement = (state) => {
    return state.statement;
};
const getCountStatement = (state) => {
    return state.countStatement;
};

export default {
    getStatement,
    getCountStatement,
    getAllBanner,
    getLoading,
    getDeleteItems,
    getPopUp,
    getUsers,
    getCurrenBannerData,
    getConfigUserbyId,
    getUserConfigData
};