import ocStatementSummaryAgentVn from '@/modules/statementReports/currency/ocStatementSummaryAgentVn';
import Layout from '@/layouts/default';

const routes = [{
    path: '/ocStatementSummaryAgentVn',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: ocStatementSummaryAgentVn,
        name: 'ocStatementSummaryAgentVn'
    }]
}];

export default routes;