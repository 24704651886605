import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    statement: {
        object: [],
    },
    countStatement: {
        objectCount: null,
    },
    configBannerData: {
        _id: null,
        user_id: null,
        amount_min: null,
        amount_max: null,
        betting_frequency: null,
    },
    currentBannerData:{},
    loading: false,
    popUp: false,
    deleteItems: []
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};