import reverseHan2 from '@/modules/reverseResult/han2';
import Layout from '@/layouts/default';

const routes = [{
    path: '/reverseHan2',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: reverseHan2,
        name: 'reverseHan2'
    }]
}];

export default routes;