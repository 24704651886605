import baccaratCh18Channel from '@/modules/channelGames/baccaratCh18Channel';
import Layout from '@/layouts/default';

const routes = [{
    path: '/baccaratCh18Channel',
    component: Layout,
    meta: {
        permission: 'master',
        requiresAuth: true
    },
    children: [{
        path: '',
        component: baccaratCh18Channel,
        name: 'baccaratCh18Channel'
    }]
}];

export default routes;