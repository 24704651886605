<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field v-model="channel.fight_no" label="Fight Number" readonly class="right-input" />
          </v-col>
          <v-col cols="4">
            <template>
              <v-select :items="[false, true]" v-model="channel.is_maintain" color="warning"
                label="Maintanance status" />
            </template>
          </v-col>
          <v-col cols="4">
            <template>
              <v-select :items="[false, true]" v-model="channel.is_stop_video" color="warning" label="Video Status" />
            </template>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="channel.maintain_desc" label="Maintanance Description" :rules="rules" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="channel.info" label="Channel Information" :rules="rules" />
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="channel.video_info" label="Video Information" :rules="rules" />
          </v-col>
          <v-col cols="2" class="w-100 justify-content-center" style="text-align:center; padding-top: 25px;">
            <v-btn @click="increaseColumn" color="blue-grey" style="width:70%;">
              Column +
            </v-btn>
          </v-col>
          <!-- <v-col cols="12">
            <v-row>
              <v-col cols="2">
                <v-switch :input-value="onEditFixPayout" @change="change(onEditFixPayout)" :label="`Apply Fix Payout`">
                </v-switch>
              </v-col>
              <v-col cols='10' v-if="onEditFixPayout">
                <v-row>
                  <v-col cols="5">
                    <v-text-field v-model="objFixPayout.meron_percentage" type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" label="ភាគរយ Payout Wala"
                      :rules="rule_meron_percentage" />
                  </v-col>
                  <v-col cols="5">
                    <v-text-field v-model="objFixPayout.wala_percentage" type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" label="ភាគរយ Payout Meron"
                      :rules="rule_wala_percentage" />
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="2" class="text-left update-chanel">
            <v-btn color="error" @click="alertResetResultDialog" :disabled="
              channel.is_open == false ||
                loading == true ||
                channel.is_open == true
                ? true
                : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Reset Result
            </v-btn>
          </v-col>
          <v-col cols="2" class="text-left text-h5">
            <span class="uppercase"> </span>
          </v-col>
          <v-col cols="3" class="text-center text-h5 channel-status-col">
            <span :class="[
              channelStatus(channel.is_open).color,
              'uppercase pl-4 channel-status',
            ]">
              {{ channelStatus(channel.is_open).status }}</span>
          </v-col>
          <v-col cols="4" class="text-right">
            <span class="uppercase update-channel-status mr-5">
              {{ channelUpdateStatus(channel.is_open).desc }}
            </span>
            <v-btn class="update-chanel-btn" :color="channelUpdateStatus(channel.is_open).background"
              @click="_updateChanelStatus" :disabled="channel.is_open == false || !canUpdateStatus">
              {{ channelUpdateStatus(channel.is_open).status }}
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right update-chanel">
            <v-btn color="primary" @click="update" :disabled="
              channel.is_open == false || loading == true ? true : false
            ">
              <v-icon class="mr-2" v-if="loading">fas fa-circle-notch fa-spin</v-icon>
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialogResetResult" persistent max-width="480" max-height="380">
      <v-card class="text-center">
        <v-card-title class="text-h6 justify-center my-5">
          Do you want to Reset Result ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey darken-1" class="cancel-btn" @click="dialogResetResult = false">
            No, Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="agree-btn" @click="_resetResult">
            Yes, Reset Result
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  props: [
    "channel",
    "objSnNumber",
    "socketChannelType",
    "socketChannelCountdown",
    "objKdPayout",
    "objCoResult"
  ],

  data() {
    return {
      valid: true,
      rules: [(value) => !!value || "Required."],
      dialogResult: false,
      confirmDialog: false,
      resultData: [],
      n1: null,
      updateChanel: [],
      canUpdateStatus: true,
      dialogResetResult: false,
      // onEditFixPayout: false,
      objFixPayout: {
        meron_percentage: 0,
        wala_percentage: 0,
      },
      rule_meron_percentage: [(v) => {
        if (
          !isNaN(parseFloat(v)) &&
          v > 0 &&
          v <= 120 &&
          v >= 20 &&
          !!v
        )
          return true;
        return "Number has to be bigger than 20 smaller than 120"
      }],
      rule_wala_percentage: [(v) => {
        if (
          v > 0 &&
          v <= 120 &&
          v >= 20 &&
          !!v
        )
          return true;
        return "Number has to be bigger than 20 smaller than 120"
      },]
    };
  },
  computed: {
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
    }),
    ...mapGetters("$_channel", {
      loading: "getLoading",
      loadingResult: "getLoadingResult",
    }),
  },
  watch: {
    socketChannelType: function () { },
    socketChannelCountdown: function () { },
    loading: function () { },
    loadingResult: function () { },
    objCoResult: function () {},
  },
  methods: {
    // calculateWala() {
    //   this.objKdPayout.wala_percentage =
    //     this.objKdPayout.meron_percentage;
    // },
    // calculateMeron() {
    //   this.objKdPayout.meron_percentage =
    //     this.objKdPayout.target_percentage - this.objKdPayout.wala_percentage;
    // },
    alertResetResultDialog() {
      this.dialogResetResult = true;
    },
    _resetResult() {
      this.$emit("submitResetResult");
      this.dialogResetResult = false;
    },
    update() {
      if (this.$refs.form.validate()) {
        this.$emit("submitUpdateChanel", this.channel);
        this.canUpdateStatus = false;
        setTimeout(this.letUpdateChanelStatus, 3000);
      }
    },

    channelStatus(status) {
      if (status === true) {
        return {
          status: "Open",
          color: "green--text",
        };
      } else if (status === false) {
        return {
          status: "Close",
          color: "red--text",
        };
      } else {
        return {
          status: "LIVE",
          color: "warning--text",
        };
      }
    },

    channelUpdateStatus(status) {
      if (status === true) {
        return {
          status: "CLOSE",
          desc: "SET Status TO",
          background: "red",
        };
      } else if (status === false) {
        return {
          status: "Please set RESULT",
          desc: "",
          background: "white",
        };
      } else {
        return {
          status: "OPEN",
          desc: "SET Status TO",
          background: "success",
        };
      }
    },

    _select() {
      this.dialogResult = true;
    },

    _selectGame() {
      this.$socket.emit("server-start-count-down", { status: "start" });
    },
    increaseColumn() {
      if(this.objCoResult.length >0){
        this.channel.column = this.channel.column + 1;
        this.$emit("submitUpdateChanel", this.channel);
      }else{
          alert("Result is Empty");
        }
    },
    _cancelResult() {
      this.confirmDialog = false;
    },

    _submitResult() {
      // this.confirmDialog = true;
    },

    async _updateChanelStatus() {
      // if (this.onEditFixPayout && this.channel.is_open == null) {
      //   let channel_type = this.$cookies.get("channel-type")
      //   let data = {
      //     channel_type: channel_type,
      //     fight_no: this.channel.fight_no,
      //     group_no: this.channel.group_no,
      //     channel_id: this.channel._id,
      //     payout_meron: parseInt(this.objFixPayout.wala_percentage) / 100,
      //     payout_wala: parseInt(this.objFixPayout.meron_percentage) / 100
      //   }
      //   const response = await this.$request.post({
      //     url: 'coChannel/updateFixedPayout',
      //     data
      //   });
      //   if (response.data.code) {
      //     this.$emit("submitUpdateStatus", this.resultData)
      //   }
      // } else {
      //   this.$emit("submitUpdateStatus", this.resultData)
      // }

      this.$emit("submitUpdateStatus", this.resultData);
      this.canUpdateStatus = false;
      setTimeout(this.letUpdateChanelStatus, 3000);
    },

    letUpdateChanelStatus() {
      this.canUpdateStatus = true;
    },
    change(a) {
      this.onEditFixPayout = !a;
    },
    ...mapMutations("$_channel", ["LOADING", "UPDATE_KDPAYOUT"]),
  },
};
</script>

<style scoped>
.channel-status {
  font-weight: 900;
  font-size: 55px;
  padding-top: 0px;
  text-shadow: 1px 1px whitesmoke;
}

.result-image {
  max-width: fit-content;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.res-img-containter {
  width: 385;
  height: 180px;
  justify-content: center;
}

.confirm-btn {
  color: white !important;
  font-weight: 700;
}

.right-input>>>input {
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  color: #fb8c00 !important;
}

.sample>>>input {
  margin-top: 5px;
}

.update-channel-status {
  font-weight: 400;
  font-size: 22px;
  padding-top: 0px;
}

.update-chanel-btn {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: -7px !important;
}

.update-chanel {
  margin-top: -2px !important;
}

.cancel-btn {
  color: white !important;
  background-color: grey !important;
}

.agree-btn {
  color: white !important;
  background-color: #3f9542 !important;
}
</style>
